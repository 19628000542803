import React, { useMemo } from 'react'

import { DialogActions, DialogContent } from '@barracuda-internal/bds-core'

import DemoAlert from 'global/components/lib/alerts/Alert'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'

import CustomizeEmailNotification from 'fir/components/lib/customizeEmailNotification/CustomizeEmailNotification'

import useSettingsTabsLogic, { SettingsTabsLogicProps } from './useSettingsTabsLogic'
import AutomatedWorkflowsSettings from './pages/AutomatedWorkflowsSettings'
import PolicySettings from './pages/PolicySettings'
import PotentialIncidentsSettings from './pages/PotentialIncidentsSettings'
import RemediationSettings from './pages/RemediationSettings'
import UserReportedSettings from './pages/UserReportedSettings'
import SiemSettings from './pages/SiemSettings'

import styles from './SettingsStyles'
import AutomaticRemediationSettings from './pages/AutomaticRemediationSettings'

export const AUTOMATIC_REMEDIATION = 'automatic_remediation'
export const MANUAL_REMEDIATION = 'manual_remediation'
export const POLICY_OPTIONS = 'policy_options'
export const USER_REPORTED = 'user_reported'
export const POTENTIAL_INCIDENTS = 'potential_incidents'
export const AUTOMATED_WORKFLOWS = 'automated_workflows'
export const SYSLOG_OPTIONS = 'syslog_options'

const SettingsTabs: React.FC<SettingsTabsLogicProps> = props => {
  const classes = styles()
  const [settingsTabsLogic] = useSettingsTabsLogic(props)

  return useMemo(() => {
    const {
      activeSettings,
      alertConfig,
      contentConfig,
      errorMsg,
      isCustomizeEmailDialogOpen,
      isSaveLoading
    } = settingsTabsLogic
    return (
      <>
        {isSaveLoading ? <LinearProgress /> : <div className={classes.adjustHeight} />}
        {errorMsg && <DemoAlert {...alertConfig} />}

        <DialogContent className={classes.dialogWrapper}>
          {activeSettings === AUTOMATED_WORKFLOWS && <AutomatedWorkflowsSettings {...contentConfig} />}
          {activeSettings === POTENTIAL_INCIDENTS && <PotentialIncidentsSettings {...contentConfig} />}
          {activeSettings === MANUAL_REMEDIATION && <RemediationSettings {...contentConfig} />}
          {activeSettings === AUTOMATIC_REMEDIATION && <AutomaticRemediationSettings {...contentConfig} />}
          {(activeSettings === MANUAL_REMEDIATION || activeSettings === AUTOMATIC_REMEDIATION) &&
            isCustomizeEmailDialogOpen && <CustomizeEmailNotification />}
          {activeSettings === POLICY_OPTIONS && <PolicySettings {...contentConfig} />}
          {activeSettings === SYSLOG_OPTIONS && <SiemSettings />}
          {activeSettings === USER_REPORTED && <UserReportedSettings {...contentConfig} />}
          <DialogActions className={classes.dialogButtons} />
        </DialogContent>
      </>
    )
  }, [classes, settingsTabsLogic])
}

export default SettingsTabs
