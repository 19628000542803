import React, { useMemo } from 'react'

import { EmailThreatScannerRev } from '@barracuda-internal/bds-core/dist/Logos/Products'

import Navbar, {
  NavbarSpacer,
  NavbarLogoBDS,
  NavbarControlWrapper,
  NavbarAccountSwitcher,
  NavbarContextMenu,
  NavbarContextMenuO365,
  NavbarContextMenuUserInfo,
  NavbarContextMenuLogout
} from 'global/components/lib/layout/navbar'

import useNavbarLogic from 'ets/components/lib/layout/useNavbarLogic'

const EtsNavbar: React.FC = () => {
  const [navbarLogic] = useNavbarLogic()

  return useMemo(() => {
    const {
      isNavbarVisible,
      isShareSecretSet,
      navbarAccountSwitcherProps,
      navbarContextMenuUserInfoProps,
      navbarContextMenuO365Props,
      onLogout
    } = navbarLogic

    return (
      <Navbar isNavbarVisible={isNavbarVisible}>
        <NavbarLogoBDS Logo={EmailThreatScannerRev} />
        {!isShareSecretSet &&
          ((
            <>
              <NavbarSpacer />
              <NavbarControlWrapper>
                <NavbarAccountSwitcher {...navbarAccountSwitcherProps} />
                <NavbarContextMenu>
                  <NavbarContextMenuUserInfo {...navbarContextMenuUserInfoProps} />
                  <NavbarContextMenuO365 {...navbarContextMenuO365Props} />
                  <NavbarContextMenuLogout onLogout={onLogout} />
                </NavbarContextMenu>
              </NavbarControlWrapper>
            </>
          ) as any)}
      </Navbar>
    )
  }, [navbarLogic])
}

export default EtsNavbar
