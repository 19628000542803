import React, { useMemo } from 'react'
import { DateTime } from 'luxon'
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography
} from '@barracuda-internal/bds-core'
import {
  AlarmOff,
  AlarmOn,
  Close,
  Delete,
  Edit,
  Launch,
  MenuVertical
} from '@barracuda-internal/bds-core/dist/Icons/Core'

import ScheduledReportsDrawer from 'global/components/features/scheduledReports/scheduledReportsDrawer/ScheduledReportsDrawer'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import SearchField from 'global/components/lib/searchField/SearchField'
import IMAGES from 'global/configs/theme/images.config'
import { useFormatMessage } from 'global/lib/localization'
import { ReportNameDropDown, ScheduledReport, ScheduledReportsDrawerType } from 'global/types/api/scheduledReports'

import useScheduledReportsLogic from './useScheduledReportsLogic'
import styles from './scheduledReportsStyles'

const BASE_I18N_KEY = 'app.scheduled_reports'
const MAX_REPORT_NAME_LENGTH = 80

export interface ScheduledReportsDrawerConfig {
  errorConfig: {
    alert: boolean
    content: string
    title: string
  }
  isCreateScheduledReportSuccess: boolean
  isUpdateScheduledReportSuccess: boolean
  onCreateUpdateScheduledReport: (uuid: string, config: ScheduledReportsDrawerType) => void
  reportNameList: {
    barracudaReports: ReportNameDropDown[]
    customReports: ReportNameDropDown[]
  }
  resetScheduledReport: () => void
}

export interface GlobalScheduledReportsProps {
  handleOpenScheduledReport: (uuid?: string, fixedReportName?: boolean) => void
  hideTitle?: boolean
  isLoading: boolean
  isScheduledReportsDrawerOpen: boolean
  newScheduledReportButtonLabel?: string
  onClickEnabledState: (schedule?: ScheduledReport) => void
  onClickDelete: (reportUuid?: string) => void
  onClickOpenReport: (reportUuid?: string) => void
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  pageTitle?: string
  scheduledReportsDrawerConfig: ScheduledReportsDrawerConfig
  schedules: ScheduledReport[]
  searchValue: string
  toggleDrawer: (event: React.KeyboardEvent | React.MouseEvent) => void
}

const ScheduledReports: React.FC<GlobalScheduledReportsProps> = ({
  handleOpenScheduledReport,
  hideTitle,
  isLoading,
  isScheduledReportsDrawerOpen,
  newScheduledReportButtonLabel,
  onClickEnabledState,
  onClickDelete,
  onClickOpenReport,
  onSearchChange,
  scheduledReportsDrawerConfig,
  schedules,
  searchValue,
  toggleDrawer
}) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [scheduledReportsLogic] = useScheduledReportsLogic({ isScheduledReportsDrawerOpen, schedules })

  const {
    anchorEl,
    formatRelativeDateRange,
    handleScheduleCardMenuClick,
    handleScheduleCardMenuClose,
    open,
    scheduleFrequency,
    targetedSchedule
  } = scheduledReportsLogic

  const {
    errorConfig,
    isCreateScheduledReportSuccess,
    isUpdateScheduledReportSuccess,
    resetScheduledReport
  } = scheduledReportsDrawerConfig

  return useMemo(
    () => (
      <Grid container direction="row">
        {isLoading && <LinearProgress className={classes.linearProgress} />}
        <ScheduledReportsDrawer
          isScheduleDialogOpen={isScheduledReportsDrawerOpen}
          toggleScheduleDialog={toggleDrawer}
          scheduledReportsDrawerConfig={scheduledReportsDrawerConfig}
        />
        <Grid item container component={Paper} xs={12} className={classes.header} elevation={0} square>
          <Grid
            item
            container
            xs={12}
            direction="row"
            justifyContent={hideTitle ? 'flex-end' : 'space-between'}
            className={classes.headerItem}
          >
            {!hideTitle && <Typography variant="h4">{formatMessage('title')}</Typography>}
            {newScheduledReportButtonLabel && (
              <Button
                color="primary"
                size="large"
                variant="contained"
                onClick={e => {
                  handleOpenScheduledReport()
                  toggleDrawer(e)
                }}
              >
                {newScheduledReportButtonLabel}
              </Button>
            )}
          </Grid>
          <Grid item xs={12} className={classes.headerItem}>
            <SearchField
              value={searchValue}
              onChange={onSearchChange}
              searchContainerClassName={classes.searchContainer}
              placeholder={formatMessage('search_placeholder')}
            />
          </Grid>
        </Grid>
        {schedules.length === 0 && (
          <Grid container item direction="column" xs={12} justifyContent="center" alignContent="center">
            <img width={319} height={220} alt={formatMessage('empty_state_text')} src={IMAGES.emptyStateSchedules} />
            <Typography variant="h5">{formatMessage('empty_state_text')}</Typography>
          </Grid>
        )}
        {schedules.length > 0 && (
          <Grid item container direction="column" justifyContent="flex-start" className={classes.schedulesContainer}>
            <Menu
              id="schedule-card-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleScheduleCardMenuClose}
              transformOrigin={{ horizontal: 'left', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              elevation={0}
              PaperProps={{
                className: classes.menuPaper
              }}
            >
              <MenuItem
                button
                onClick={() => {
                  onClickEnabledState(targetedSchedule)
                  handleScheduleCardMenuClose()
                }}
              >
                <ListItemIcon>
                  {targetedSchedule?.enabled ? <AlarmOff fontSize="small" /> : <AlarmOn fontSize="small" />}
                </ListItemIcon>
                <ListItemText className={classes.menuListItemText}>
                  {targetedSchedule?.enabled ? formatMessage('disable_schedule') : formatMessage('enable_schedule')}
                </ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onClickDelete(targetedSchedule?.uuid)
                  handleScheduleCardMenuClose()
                }}
              >
                <ListItemIcon>
                  <Delete fontSize="small" />
                </ListItemIcon>
                <ListItemText className={classes.menuListItemText}>{formatMessage('delete_schedule')}</ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => onClickOpenReport(targetedSchedule?.reportUuid)}>
                <ListItemIcon>
                  <Launch fontSize="small" />
                </ListItemIcon>
                <ListItemText className={classes.menuListItemText}>{formatMessage('open_report')}</ListItemText>
              </MenuItem>
            </Menu>
            {schedules.map(schedule => (
              <Card key={schedule.uuid} className={classes.scheduleCard} raised>
                <CardHeader
                  title={
                    <Tooltip
                      title={schedule.reportName.length > MAX_REPORT_NAME_LENGTH ? schedule.reportName : ''}
                      placement="bottom"
                      interactive
                    >
                      <Typography
                        variant="h5"
                        className={`${schedule.enabled ? classes.cardTitle : classes.cardTitleDisabled}`}
                      >
                        {formatMessage('schedule_title', {
                          reportName:
                            schedule.reportName.length > MAX_REPORT_NAME_LENGTH
                              ? `${schedule.reportName.slice(0, MAX_REPORT_NAME_LENGTH)}...`
                              : schedule.reportName,
                          format: schedule.format.toUpperCase()
                        })}
                      </Typography>
                    </Tooltip>
                  }
                  subheader={
                    <Typography
                      variant="overline"
                      color="secondary"
                      className={schedule.enabled ? '' : classes.cardTitleDisabled}
                    >
                      {formatMessage('schedule_frequency', {
                        relativeDateRange: formatRelativeDateRange(schedule.relativeDateRange),
                        frequency: schedule.frequency.toLocaleLowerCase(),
                        date: scheduleFrequency(schedule)
                      })}
                    </Typography>
                  }
                  action={
                    <>
                      <IconButton
                        onClick={e => {
                          toggleDrawer(e)
                          handleOpenScheduledReport(schedule.uuid)
                        }}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        id={schedule.uuid}
                        aria-label="more"
                        aria-controls={`schedule-card-menu-${schedule.uuid}`}
                        aria-haspopup="true"
                        onClick={handleScheduleCardMenuClick}
                      >
                        <MenuVertical />
                      </IconButton>
                    </>
                  }
                  className={classes.cardHeader}
                />
                <CardContent className={classes.cardContent}>
                  {schedule.recipients.slice(0, 10).map(recipient => (
                    <Chip
                      key={recipient}
                      label={recipient}
                      size="small"
                      className={schedule.enabled ? classes.chip : classes.chipDisabled}
                    />
                  ))}
                  {schedule.recipients.length > 10 && (
                    <Tooltip title={schedule.recipients.slice(10).join(', ')}>
                      <Chip
                        label={formatMessage('chip_extra_recipients', {
                          count: schedule.recipients.length - 10
                        })}
                        size="small"
                        className={schedule.enabled ? classes.chip : classes.chipDisabled}
                      />
                    </Tooltip>
                  )}
                </CardContent>
                <CardActions className={classes.cardActions}>
                  {schedule.enabled ? (
                    <Grid className={classes.enabledStatusContainer}>
                      <AlarmOn color="secondary" />
                      <Typography variant="subtitle1" className={classes.scheduleEnabledText}>
                        {formatMessage('next_schedule', {
                          date: DateTime.fromISO(schedule.nextRun).toLocaleString(DateTime.DATE_MED),
                          time: DateTime.fromISO(schedule.nextRun).toLocaleString(DateTime.TIME_SIMPLE)
                        })}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid className={classes.enabledStatusContainer}>
                      <AlarmOff color="secondary" />
                      <Typography variant="subtitle1" className={classes.scheduleEnabledText}>
                        {formatMessage('disabled')}
                      </Typography>
                    </Grid>
                  )}
                  <Grid>
                    <Typography variant="subtitle1">
                      {schedule.lastRun
                        ? formatMessage('last_sent', {
                            date: DateTime.fromISO(schedule.lastRun).toLocaleString(DateTime.DATE_MED),
                            time: DateTime.fromISO(schedule.lastRun).toLocaleString(DateTime.TIME_SIMPLE)
                          })
                        : formatMessage('never_sent')}
                    </Typography>
                  </Grid>
                </CardActions>
              </Card>
            ))}
          </Grid>
        )}
        {(isCreateScheduledReportSuccess || isUpdateScheduledReportSuccess) && (
          <Alert className={classes.alert} severity="success" action={<Close onClick={resetScheduledReport} />}>
            <AlertTitle>
              {formatMessage(`alert.success.${isCreateScheduledReportSuccess ? 'create' : 'update'}.title`)}
            </AlertTitle>
            {formatMessage(`alert.success.${isCreateScheduledReportSuccess ? 'create' : 'update'}.content`)}
          </Alert>
        )}
        {errorConfig.alert && (
          <Alert className={classes.alert} severity="error" action={<Close onClick={resetScheduledReport} />}>
            <AlertTitle>{errorConfig.title}</AlertTitle>
            {errorConfig.content}
          </Alert>
        )}
      </Grid>
    ),
    [
      anchorEl,
      classes.alert,
      classes.cardActions,
      classes.cardContent,
      classes.cardHeader,
      classes.cardTitle,
      classes.cardTitleDisabled,
      classes.chip,
      classes.chipDisabled,
      classes.enabledStatusContainer,
      classes.header,
      classes.headerItem,
      classes.linearProgress,
      classes.menuListItemText,
      classes.menuPaper,
      classes.scheduleCard,
      classes.scheduleEnabledText,
      classes.schedulesContainer,
      classes.searchContainer,
      errorConfig.alert,
      errorConfig.content,
      errorConfig.title,
      formatMessage,
      formatRelativeDateRange,
      handleOpenScheduledReport,
      handleScheduleCardMenuClick,
      handleScheduleCardMenuClose,
      hideTitle,
      isCreateScheduledReportSuccess,
      isLoading,
      isScheduledReportsDrawerOpen,
      isUpdateScheduledReportSuccess,
      newScheduledReportButtonLabel,
      onClickDelete,
      onClickEnabledState,
      onClickOpenReport,
      onSearchChange,
      open,
      resetScheduledReport,
      scheduleFrequency,
      scheduledReportsDrawerConfig,
      schedules,
      searchValue,
      targetedSchedule,
      toggleDrawer
    ]
  )
}

export default ScheduledReports
