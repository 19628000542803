const UI_ROUTES = {
  AUTOMATED_WORKFLOWS: {
    path: '/report/:accessToken/workflows'
  },
  AUTOMATED_WORKFLOWS_WORKFLOW: {
    path: '/report/:accessToken/workflows/:workflowId'
  },
  INSIGHTS: {
    path: '/report/:accessToken/insights'
  },
  INSIGHTS_AUTOMATED: {
    path: '/report/:accessToken/insights/automated'
  },
  INSIGHTS_GEOIP: {
    path: '/report/:accessToken/insights/geoip'
  },
  INSIGHTS_GEOIP_COUNTRY: {
    path: '/report/:accessToken/insights/geoip/:country'
  },
  LICENSE: {
    path: '/report/:accessToken/licenses'
  },
  MISSING_REQUIREMENTS: {
    path: '/missing-requirements/:type'
  },
  NEW_INCIDENT: {
    path: '/new-incident'
  },
  ONBOARDING: {
    path: '/onboarding'
  },
  EMPTY_REPORT: {
    path: '/report'
  },
  REPORT: {
    path: '/report/:accessToken'
  },
  REMEDIATION: {
    path: '/report/:accessToken/remediation'
  },
  REMEDIATION_INCIDENT_ROOT: {
    path: '/report/:accessToken/remediation/incident'
  },
  REMEDIATION_INCIDENT: {
    path: '/report/:accessToken/remediation/incident/:incidentId'
  },
  SIGNIN_CONNECT: {
    path: '/signin/connect'
  },
  SIGNIN_REMEDIATES: {
    path: '/signin/remediates'
  },
  SIGNUP_COMPLETE: {
    path: '/signup/complete'
  },
  SIGNUP_COMPLETE_POLICY: {
    path: '/signup/complete/policy'
  },
  SIGNIN_SIGNUP: {
    path: '/signup'
  },
  START_TRIAL: {
    path: '/start-trial'
  },
  SETTINGS: {
    path: '/report/:accessToken/settings'
  },
  USER_REPORTED: {
    path: '/report/:accessToken/user-reported/emails'
  },
  USER_REPORTED_ROOT: {
    path: '/report/:accessToken/user-reported'
  },
  UNIFIED_REPORTING_ROOT: {
    path: '/report/:accessToken/unified-reporting'
  },
  REPORT_LIST: {
    path: '/report/:accessToken/unified-reporting/report'
  },
  REPORT_LIST_REPORT: {
    path: '/report/:accessToken/unified-reporting/report/:reportId'
  },
  SCHEDULED_REPORTS: {
    path: '/report/:accessToken/unified-reporting/scheduled-reports'
  }
}

export default UI_ROUTES
