import { useCallback, useMemo, useState } from 'react'

import {
  ScheduledReportsDrawerConfig,
  GlobalScheduledReportsProps
} from 'global/components/features/scheduledReports/ScheduledReports'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { useFormatMessage } from 'global/lib/localization'
import { useEffectOnInit } from 'global/lib/useCustomEffect'
import { isPending, isSuccess } from 'global/redux/toolkit/api'
import {
  createScheduledReport,
  deleteScheduledReport,
  getScheduledReports,
  INITIAL_SCHEDULED_REPORT,
  setErrorMessage as setScheduledReportError,
  resetScheduledReport,
  update,
  updateScheduledReport
} from 'global/redux/features/scheduledReports/scheduledReportsSlice'
import {
  getUnifiedReportingReports,
  setErrorMessage as setUnifiedReportingError
} from 'global/redux/features/unifiedReporting/unifiedReportingSlice'
import { ReportNameDropDown, ScheduledReport, ScheduledReportsDrawerType } from 'global/types/api/scheduledReports'
import { UnifiedReportingReport, UnifiedReportingReportType } from 'global/types/api/unifiedReporting'

import routesConfig from 'fir/lib/routes/routesConfig'
import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'

const BASE_I18N_KEY = 'app.scheduled_reports'
const ERROR_I18N_KEY = 'app.error.snackbar_errors'

interface ScheduledReportsProps extends GlobalScheduledReportsProps {
  newScheduledReportButtonLabel: string
}

const useScheduledReportsLogic = (): ScheduledReportsProps[] => {
  const dispatch = useAppDispatch()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatErrorMessage = useFormatMessage(ERROR_I18N_KEY)

  const {
    errorMessage,
    isCreateScheduledReportSuccess,
    isFirDemoUser,
    isLoading,
    isUpdateScheduledReportSuccess,
    reports,
    scheduledReport,
    scheduledReports
  } = useAppSelector(_stores => ({
    errorMessage: _stores.scheduledReports.errorMessage,
    isCreateScheduledReportSuccess: isSuccess(_stores.scheduledReports.createScheduledReportApiStatus),
    isFirDemoUser: _stores.user.isFirDemoUser,
    isLoading: isPending(_stores.scheduledReports.getScheduledReportsApiStatus),
    isUpdateScheduledReportSuccess: isSuccess(_stores.scheduledReports.updateScheduledReportApiStatus),
    reports: _stores.unifiedReporting.reports,
    scheduledReport: _stores.scheduledReports.scheduledReport,
    scheduledReports: _stores.scheduledReports.scheduledReports
  }))

  const [isScheduledReportsDrawerOpen, setIsScheduledReportsDrawerOpen] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')

  useEffectOnInit(() => {
    dispatch(getUnifiedReportingReports({ product: 'IR' }))
    dispatch(getScheduledReports({ product: 'IR' }))

    analyticsLib.trackAppEvent(analyticsLib.EVENTS.SCHEDULED_REPORTS_PAGEVIEW)
    return () => {
      dispatch(setUnifiedReportingError(''))
      dispatch(setScheduledReportError(''))
    }
  }, [])

  const toggleDrawer = useCallback(
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }

      if (!isScheduledReportsDrawerOpen) {
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.SCHEDULED_REPORT_OPEN_SCHEDULE_MODAL)
      }

      setIsScheduledReportsDrawerOpen(!isScheduledReportsDrawerOpen)
    },
    [isScheduledReportsDrawerOpen]
  )

  const onSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }, [])

  const handleEnabledState = useCallback(
    (schedule?: ScheduledReport) => {
      if (!schedule) return

      if (isFirDemoUser) {
        dispatch(setScheduledReportError('unified_reporting_demo_error'))
        dispatch(setUnifiedReportingError('unified_reporting_demo_error'))
        return
      }

      dispatch(
        updateScheduledReport({
          uuid: schedule.uuid,
          config: {
            ...schedule,
            enabled: !schedule.enabled
          }
        })
      )
        .unwrap()
        .then(() => {
          dispatch(getScheduledReports({ product: 'IR' }))

          const analyticsEnabledState = schedule.enabled
            ? analyticsLib.EVENTS.SCHEUDLED_REPORT_DISABLED
            : analyticsLib.EVENTS.SCHEDULED_REPORT_ENABLED

          analyticsLib.trackAppEvent(analyticsEnabledState, {
            scheduleUuid: schedule.uuid,
            dateRange: schedule.relativeDateRange,
            format: schedule.format,
            frequency: schedule.frequency,
            reportType: reports.find(report => report.uuid === schedule.reportUuid)?.type
          })
        })
    },
    [dispatch, isFirDemoUser, reports]
  )

  const handleDelete = useCallback(
    (uuid?: string) => {
      if (!uuid) return

      if (isFirDemoUser) {
        dispatch(setScheduledReportError('unified_reporting_demo_error'))
        dispatch(setUnifiedReportingError('unified_reporting_demo_error'))
        return
      }

      dispatch(deleteScheduledReport({ uuid }))
        .unwrap()
        .then(() => {
          dispatch(getScheduledReports({ product: 'IR' }))

          const currentSchedule = scheduledReports.find(schedule => schedule.uuid === uuid)

          analyticsLib.trackAppEvent(analyticsLib.EVENTS.SCHEDULED_REPORT_DELETE, {
            scheduleUuid: uuid,
            dateRange: currentSchedule?.relativeDateRange,
            frequency: currentSchedule?.frequency,
            reportType: reports.find(report => report.uuid === currentSchedule?.reportUuid)?.type
          })
        })
    },
    [dispatch, isFirDemoUser, reports, scheduledReports]
  )

  const handleOpenScheduledReport = useCallback(
    (uuid?: string, isReportSelected?: boolean) => {
      if (!uuid) {
        return
      }

      if (isReportSelected) {
        // creating a new schedule from a report on the unified reporting page
        const selectedReport = reports.find(report => report.uuid === uuid)
        if (selectedReport) {
          dispatch(
            update({
              scheduledReport: {
                ...INITIAL_SCHEDULED_REPORT,
                isReportNameFixed: true,
                reportName: selectedReport.name,
                reportUuid: selectedReport.uuid
              }
            })
          )
        }
      } else {
        // updating an existing schedule from the scheduled reports page
        const openedScheduledReport = scheduledReports.find((r: { uuid: string }) => r.uuid === uuid)

        if (openedScheduledReport) {
          dispatch(
            update({
              scheduledReport: { ...openedScheduledReport, isReportNameFixed: true }
            })
          )
        }
      }
    },
    [dispatch, reports, scheduledReports]
  )

  const handleOpenReport = useCallback((reportUuid?: string) => {
    if (!reportUuid) return

    routesConfig.UNIFIED_REPORTING.goto({
      reportId: reportUuid
    })
  }, [])

  const reportNameList = useMemo(() => {
    const barracudaReports: ReportNameDropDown[] = []
    const customReports: ReportNameDropDown[] = []

    reports.forEach((report: UnifiedReportingReport) => {
      if (report.type === UnifiedReportingReportType.BARRACUDA) {
        barracudaReports.push({
          reportName: report.name,
          reportId: report.uuid
        })
      }

      if (report.type === UnifiedReportingReportType.CUSTOM) {
        customReports.push({
          reportName: report.name,
          reportId: report.uuid
        })
      }
    })

    return {
      barracudaReports,
      customReports
    }
  }, [reports])

  const onCreateUpdateScheduledReport = useCallback(
    (uuid: string, config: ScheduledReportsDrawerType) => {
      if (uuid) {
        if (isFirDemoUser) {
          dispatch(setScheduledReportError('unified_reporting_demo_error'))
          dispatch(setUnifiedReportingError('unified_reporting_demo_error'))
          return
        }

        analyticsLib.trackAppEvent(analyticsLib.EVENTS.SCHEDULED_REPORT_UPDATE, {
          scheduleUuid: uuid,
          dateRange: scheduledReport.relativeDateRange,
          format: scheduledReport.format,
          frequency: scheduledReport.frequency,
          reportType: reports.find(report => report.uuid === scheduledReport.reportUuid)?.type
        })

        dispatch(
          updateScheduledReport({
            uuid,
            config: { ...config, product: 'IR' }
          })
        )
          .unwrap()
          .then(() => {
            dispatch(getScheduledReports({ product: 'IR' }))
          })
          .catch((error: string) => {
            dispatch(setScheduledReportError(error))
            dispatch(setUnifiedReportingError(error))
          })
      } else {
        if (isFirDemoUser) {
          dispatch(setScheduledReportError('unified_reporting_demo_error'))
          dispatch(setUnifiedReportingError('unified_reporting_demo_error'))
          return
        }

        analyticsLib.trackAppEvent(analyticsLib.EVENTS.SCHEUDLED_REPORT_CREATE, {
          dateRange: scheduledReport.relativeDateRange,
          format: scheduledReport.format,
          frequency: scheduledReport.frequency,
          reportType: reports.find(report => report.uuid === scheduledReport.reportUuid)?.type
        })

        dispatch(
          createScheduledReport({
            config: { ...config, product: 'IR' }
          })
        )
          .unwrap()
          .then(() => {
            dispatch(getScheduledReports({ product: 'IR' }))
          })
          .catch((error: any) => {
            dispatch(setScheduledReportError(error))
            dispatch(setUnifiedReportingError(error))
          })
      }
    },
    [
      dispatch,
      isFirDemoUser,
      reports,
      scheduledReport.format,
      scheduledReport.frequency,
      scheduledReport.relativeDateRange,
      scheduledReport.reportUuid
    ]
  )

  const scheduledReportsDrawerConfig: ScheduledReportsDrawerConfig = useMemo(() => {
    return {
      // For new errors prompted by the back end, make sure to add a title and content to the translation object
      errorConfig: {
        alert: errorMessage.length > 0,
        title: errorMessage ? formatErrorMessage(`${errorMessage}.title`) : '',
        content: errorMessage ? formatErrorMessage(`${errorMessage}.content`) : ''
      },
      isCreateScheduledReportSuccess,
      isUpdateScheduledReportSuccess,
      onCreateUpdateScheduledReport,
      reportNameList,
      resetScheduledReport: () => {
        dispatch(resetScheduledReport())
        dispatch(setScheduledReportError(''))
        dispatch(setUnifiedReportingError(''))
      }
    }
  }, [
    dispatch,
    errorMessage,
    formatErrorMessage,
    isCreateScheduledReportSuccess,
    isUpdateScheduledReportSuccess,
    onCreateUpdateScheduledReport,
    reportNameList
  ])

  return useMemo(
    () => [
      {
        handleOpenScheduledReport,
        isScheduledReportsDrawerOpen,
        isLoading,
        newScheduledReportButtonLabel: formatMessage('new_scheduled_report'),
        onClickDelete: handleDelete,
        onClickOpenReport: handleOpenReport,
        onClickEnabledState: handleEnabledState,
        onSearchChange,
        pageTitle: formatMessage('title'),
        searchValue,
        scheduledReportsDrawerConfig,
        schedules: scheduledReports.filter(
          schedule =>
            schedule.reportName.toLowerCase().includes(searchValue.toLowerCase()) ||
            schedule.recipients.some(recipient => recipient.toLowerCase().includes(searchValue.toLowerCase()))
        ),
        toggleDrawer
      }
    ],
    [
      handleOpenScheduledReport,
      isScheduledReportsDrawerOpen,
      isLoading,
      formatMessage,
      handleDelete,
      handleOpenReport,
      handleEnabledState,
      onSearchChange,
      searchValue,
      scheduledReportsDrawerConfig,
      scheduledReports,
      toggleDrawer
    ]
  )
}

export default useScheduledReportsLogic
