import React, { useMemo } from 'react'

import NavigationBar from 'global/components/lib/navigation/NavigationBar'

import useFirNavbarLogic from 'fir/components/lib/layout/useNavbarLogic'

const FirNavbar: React.FC = () => {
  const [firNavbarLogic] = useFirNavbarLogic()

  const { title, logo, currentPath, onNavigate, routes, options } = firNavbarLogic

  return useMemo(
    () => (
      <NavigationBar
        title={title}
        logo={logo}
        currentPath={currentPath}
        routes={routes}
        options={options}
        onNavigate={onNavigate}
      />
    ),
    [currentPath, logo, onNavigate, options, routes, title]
  )
}

export default FirNavbar
