import React from 'react'

import { NavigationBar, NavigationBarProps } from '@barracuda-internal/bds-core'

const NavBar = ({ title, logo, options, routes, currentPath, onNavigate }: NavigationBarProps): JSX.Element => (
  <NavigationBar
    title={title}
    logo={logo}
    options={options}
    routes={routes}
    currentPath={currentPath}
    onNavigate={onNavigate}
  />
)

export default NavBar
