import { useMemo, useReducer, useCallback } from 'react'

import { config } from 'global/lib/config'
import * as globalO365Connect from 'global/lib/o365Connect/o365Connect'
import useUserDataLib from 'global/lib/userData/useUserData'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import { setResetPassword, resetCompromisedAccount } from 'sen/redux/features/ato/atoSlice'

export type OnResetPassword = () => void
export type OnResetCompromisedAccount = () => void

export interface ResetPasswordDialogConfig {
  onResetPassword: OnResetPassword
  displayName: string
  buttonDisabled: boolean
  connectError: string
  showConnectError: boolean
  onResetCompromisedAccount: OnResetCompromisedAccount
}

export interface State {
  connectError: string
  showConnectError: boolean
}

export default function useResetPasswordDialogLogic(): [ResetPasswordDialogConfig] {
  const { accessTokenId, compromisedAccount } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id,
    compromisedAccount: _stores.ato.compromisedAccount
  }))
  const [state, setState] = useReducer((_state: State, newState: Partial<State>) => ({ ..._state, ...newState }), {
    connectError: 'default',
    showConnectError: false
  })
  const dispatch = useAppDispatch()

  const [userDataLib] = useUserDataLib()

  const onResetPassword = useCallback(() => {
    if (accessTokenId && compromisedAccount?.emailAddress) {
      const accountId = userDataLib.getAccountByAccessToken(accessTokenId)?.accountId as string

      globalO365Connect.initResetPasswordATO({
        provider: config.CLOUD_PROVIDERS.office365.id,
        source: config.RECONNECT_SOURCES.ATO_PASS_RESET,
        scanType: config.SCAN_TYPES.SENTINEL,
        accountId,
        accessTokenId,
        userPrincipalName: compromisedAccount.emailAddress,
        connectSuccess: data => {
          dispatch(setResetPassword(data))
        },
        authFailure: data => {
          setState({
            showConnectError: true,
            connectError: `connect_o365_dialog.${globalO365Connect.authFailureError(data)}`
          })
        }
      })
    }
  }, [accessTokenId, compromisedAccount, userDataLib, dispatch])

  const onResetCompromisedAccount = useCallback(() => {
    dispatch(resetCompromisedAccount())
  }, [dispatch])

  return useMemo(() => {
    return [
      {
        displayName: compromisedAccount?.displayName || compromisedAccount?.emailAddress || '',
        onResetPassword,
        buttonDisabled: false,
        showConnectError: state.showConnectError,
        connectError: state.connectError,
        onResetCompromisedAccount
      }
    ]
  }, [compromisedAccount, onResetPassword, state.showConnectError, state.connectError, onResetCompromisedAccount])
}
