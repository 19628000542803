import deepmerge from 'deepmerge'
import { makeStyles } from '@material-ui/core/styles'
import { makeGridStyles } from 'global/components/lib/grid/gridStyles'

export default makeStyles(theme => ({
  actionsList: {
    paddingInlineStart: 20,
    '& li': {
      paddingTop: theme.spacing(1)
    }
  },
  bold: {
    fontWeight: theme.font.weight.bold
  },
  breadcrumb: {
    margin: theme.spacing(2, 2, 0, 2)
  },
  cellHeader: {
    margin: theme.spacing(0, 0, 2, 0)
  },
  cellSubHeader: {
    margin: theme.spacing(2, 0, 2, 0),
    padding: theme.spacing(2, 0, 0, 0),
    borderTop: 1,
    borderTopStyle: 'solid',
    borderColor: theme.palette.grey[300]
  },
  cellTitle: {},
  creDetails: {
    display: 'inline-block',
    paddingLeft: theme.spacing(3)
  },
  creLabel: {
    paddingTop: theme.spacing(0.5)
  },
  creLinearWrapper: {
    paddingTop: 14
  },
  details: {
    padding: theme.spacing(0, 1, 0, 1)
  },
  detailsCell: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 2
  },
  detailsWrapper: {
    margin: theme.spacing(2, 2, 2, 2)
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  header: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    position: 'relative',
    zIndex: 1,
    height: '100%'
  },
  link: {
    bottom: theme.spacing(2),
    padding: theme.spacing(2),
    position: 'absolute',
    lineHeight: '20px',
    cursor: 'pointer',
    textDecoration: 'underline',
    color: '#0088CE'
  },
  pageAlert: {
    margin: theme.spacing(0, 2, 3, 2)
  },
  textLowercase: {
    textTransform: 'lowercase'
  },
  wrapper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 2,
    margin: theme.spacing(2, 2, 3, 2),
    overflow: 'hidden',
    position: 'relative'
  }
}))

export const gridStyle = {
  gridLayout: {
    '& .k-grid-header col:nth-of-type(1)': {
      width: '15%'
    },
    '& .k-grid-table col:nth-of-type(1)': {
      width: '15%'
    },
    '& .k-grid-header col:nth-of-type(2)': {
      width: '25%'
    },
    '& .k-grid-table col:nth-of-type(2)': {
      width: '25%'
    },
    '& .k-grid-header col:nth-of-type(3)': {
      width: '15%'
    },
    '& .k-grid-table col:nth-of-type(3)': {
      width: '15%'
    }
  }
}

export const threatGridStyle = makeStyles(theme => ({ ...deepmerge(makeGridStyles(theme), gridStyle) }))
