import React, { useCallback, useMemo } from 'react'
import { matchRoutes, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material/styles'

import { Badge } from '@barracuda-internal/bds-core'

import Tabs, { TabsProps } from 'global/components/lib/Tabs/Tabs'
import styles from 'global/components/lib/Tabs/tabsStyles'

import { useFormatMessage } from 'global/lib/localization'

import Layout from 'fir/components/lib/layout/Layout'
import routesConfig from 'fir/lib/routes/routesConfig'

export interface InsightsProps {
  tabsConfig: TabsProps
  match: any
}

const BASE_I18N_KEY = 'fir.app.insights'

const POTENTIAL_INCIDENTS = 'potential_incidents'
const EMAILS_BY_REGION = 'emails_by_region'

const TABS = [
  {
    id: POTENTIAL_INCIDENTS,
    route: routesConfig.INSIGHTS_AUTOMATED,
    activePaths: [routesConfig.INSIGHTS_AUTOMATED]
  },
  {
    id: EMAILS_BY_REGION,
    route: routesConfig.INSIGHTS_GEOIP,
    activePaths: [routesConfig.INSIGHTS_GEOIP, routesConfig.INSIGHTS_GEOIP_COUNTRY]
  }
]

export default function insightsLogic<P extends {}>(WrappedComponent: React.ComponentType<P>): React.FC<P> {
  const InsightsLogic: React.FC<any> = props => {
    const location = useLocation()
    const theme = useTheme()
    const activeTab = useMemo(() => {
      return (TABS.find((tab: any) => matchRoutes(tab.activePaths, location.pathname)) || {}).id
    }, [location.pathname])
    const { insights }: any = useSelector((_stores: any) => ({
      insights: _stores.insights
    }))
    const formatMessage = useFormatMessage(BASE_I18N_KEY)

    const tabsConfig = useCallback(() => {
      const classes = styles()

      return {
        activeTab,
        tabs: TABS.map((tab: any) => ({
          id: tab.id,
          onClick: () => {
            if (activeTab !== tab.route.path) {
              tab.route.goto()
            }
          },
          label:
            tab.id === POTENTIAL_INCIDENTS ? (
              <Badge
                classes={{ badge: classes.styledBadge }}
                badgeContent={insights.postDelivery.length + insights.related.length}
              >
                {formatMessage(`tabs.${tab.id}`)}
              </Badge>
            ) : (
              formatMessage(`tabs.${tab.id}`)
            )
        }))
      }
    }, [activeTab, formatMessage, insights.postDelivery.length, insights.related.length])

    return (
      <Layout
        pageTitle={formatMessage(`${activeTab}.labels.page_title`)}
        pageSubtitle={formatMessage(`${activeTab}.labels.page_subtitle`)}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            backgroundColor: theme.palette.background.paper
          }}
        >
          <Tabs {...(tabsConfig() as TabsProps)} />
        </div>
        <WrappedComponent {...(props as any)} />
      </Layout>
    )
  }

  return InsightsLogic
}
