import React from 'react'

import Alert from 'global/components/lib/alerts/Alert'
import useEmptyReportLogic from 'global/components/features/emptyReport/useEmptyReportLogic'

export interface EmptyReportProps {
  handler: (accessTokenId: string) => void | (() => void)
}

const EmptyReport: React.FC<EmptyReportProps> = ({ handler }) => {
  const [errorMessage] = useEmptyReportLogic(handler)

  return errorMessage ? <Alert alertContent={errorMessage} /> : null
}

export default EmptyReport
