import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import GlobalSigninConnect from 'global/components/features/signinSignup/signinConnect/SigninConnect'
import { useFormatMessage } from 'global/lib/localization'

import SigninSignupLayout from 'fir/components/lib/layout/SigninSignupLayout'
import routesConfig from 'fir/lib/routes/routesConfig'
import o365Connect from 'fir/components/lib/o365Connect/o365Connect'
import useSigninConnectLogic from 'fir/components/pages/signinConnect/useSigninConnectLogic'

const BASE_I18N_KEY = 'fir.app.signin_connect'

export const SigninConnect: React.FC = () => {
  const [signinConnectLogic] = useSigninConnectLogic()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const location = useLocation()

  return useMemo(
    () => (
      <SigninSignupLayout error={signinConnectLogic.error}>
        <GlobalSigninConnect
          appName={formatMessage('app_name')}
          localizationPath="fir.app.signin_connect"
          location={location}
          routesConfig={routesConfig}
          o365Connect={o365Connect}
        />
      </SigninSignupLayout>
    ),
    [formatMessage, location, signinConnectLogic.error]
  )
}

export default SigninConnect
