import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  bold: {
    fontWeight: theme.font.weight.bold
  },
  close: {
    float: 'right'
  },
  wrapper: {
    padding: `${theme.spacing(2)}px !important`,
    background: theme.colors.hoverGray
  }
}))
