import React from 'react'

import { Box, Tab, Tabs } from '@barracuda-internal/bds-core'

import styles from './tabsStyles'

export interface TabsProps {
  activeTab: string | number
  tabs: TabType[]
  vertical?: boolean
}

export interface TabType {
  id: string | number
  label: any
  onClick: (e: MouseEvent) => void
}

const TabsComponent: React.FC<TabsProps> = ({ activeTab, tabs, vertical = false }) => {
  const classes = styles()

  return (
    <div className={vertical ? undefined : classes.root}>
      <Box className={vertical ? classes.verticalNavbar : classes.horizontalNavbar}>
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
          orientation={vertical ? 'vertical' : 'horizontal'}
        >
          {tabs.map((tab: TabType) => (
            <Tab
              label={<div className={vertical ? classes.tabWrapper : undefined}>{tab.label}</div>}
              className={classes.styledTabs}
              data-field={tab.id}
              key={tab.id}
              onClick={tab.onClick}
              value={tab.id}
            />
          ))}
        </Tabs>
      </Box>
    </div>
  )
}

export default TabsComponent
