import React, { useReducer, useEffect, useMemo, useCallback } from 'react'
import { getTreatments } from '@splitsoftware/splitio-redux'
import { useLocation, useParams } from 'react-router-dom'
import { isEqual } from 'lodash'
import queryString from 'query-string'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import useAccessTokenLib from 'global/lib/accessToken/useAccessToken'
import useUserDataLib from 'global/lib/userData/useUserData'
import useProductLib from 'global/lib/product/useProduct'
import { config } from 'global/lib/config'
import { FEATURES, initSplitio } from 'global/lib/splitio/splitio'
import {
  setShareSecret,
  setCurrentAccessToken,
  setCurrentEssAccount,
  setCurrentBccAccount
} from 'global/redux/features/accessToken/accessTokenSlice'
import { isFailed, isSuccess } from 'global/redux/toolkit/api'
import { checkBCSSubscription } from 'global/redux/features/account/accountApiThunks'

import { AccessToken, SettingsValue } from 'global/types/api/accessTokenType'
import { Account } from 'global/types/api/accountType'
import { Products } from 'global/types/productsType'

import routesConfig from 'fir/lib/routes/routesConfig'
import { ReportTypes, UrlParams, ReportProps } from 'fir/components/lib/report/reportTypes'
import { forensicsGetEssAccount } from 'fir/redux/features/remediation/remediationSlice'
import { setUserEssRegion } from 'fir/redux/features/user/userSlice'
import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'

let isAlreadyInit = false
let isEssAccountAlreadyChecked = false
let accessTokenId: string | null
let accessToken: AccessToken | null

const INITIAL_STATE = {
  isLoading: true,
  confirmAddProductLoading: true,
  showConfirmAddProduct: true,
  accessTokenSetupComplete: false,
  accessTokenSetupLoading: true
}

interface ReportConfig {
  validateAppForAccessToken: (accessTokenId: string) => void
}

interface SearchParams {
  accessToken?: string
  bccId?: string
}

interface BCSSubscriptionAccount {
  accessTokens: AccessToken[]
  accountId: string
  bccId: string
  bcsEntitled: boolean
}

export default function reportLogic<P extends {}>(
  WrappedComponent: React.ComponentType<P>,
  { validateAppForAccessToken }: ReportConfig
): React.FC {
  const ReportLogic: React.FC<any> = props => {
    const [state, setState] = useReducer((_state: ReportTypes, newState: any) => ({ ..._state, ...newState }), {
      isLoading: !isAlreadyInit,
      confirmAddProductLoading: !isAlreadyInit,
      showConfirmAddProduct: !isAlreadyInit,
      accessTokenSetupComplete: isAlreadyInit,
      accessTokenSetupLoading: !isAlreadyInit
    })
    const [userDataLib] = useUserDataLib()
    const [productLib] = useProductLib()

    const {
      accessTokenStore,
      essAccount,
      isAccessTokenSet,
      isEssAccountFailed,
      isEssAccountSuccess,
      isSplitioReady,
      remediation
    } = useAppSelector(_stores => ({
      accessTokenStore: _stores.accessToken.accessToken,
      essAccount: _stores.remediation.essAccount,
      isAccessTokenSet: _stores.accessToken.isAccessTokenSet,
      isEssAccountFailed: isFailed(_stores.remediation.getEssAccountApiStatus),
      isEssAccountSuccess: isSuccess(_stores.remediation.getEssAccountApiStatus),
      isSplitioReady: _stores.splitio.isReady,
      remediation: _stores.remediation
    }))
    const urlParams: UrlParams = useParams()
    const location = useLocation()
    const dispatch = useAppDispatch()
    const [accessTokenLib] = useAccessTokenLib()

    // setEssBccAccounts
    const setEssBccAccounts = useCallback(
      (essAccountId: string | undefined, id = accessTokenId) => {
        dispatch(setCurrentEssAccount(essAccountId as string))
        if (id !== config.NO_TOKEN_TOKEN) {
          const validatedToken = userDataLib.getAccountByAccessToken(id as string)

          if (validatedToken) {
            dispatch(setCurrentBccAccount(validatedToken.bccId as string))
          }
        }
      },
      [dispatch, userDataLib]
    )

    // setBaseVariables
    const setBaseVariables = useCallback(
      (essAccountId?: string) => {
        if (!accessToken && accessTokenId) {
          accessToken = userDataLib.getAccessTokenById(accessTokenId) as AccessToken
        }

        if (accessToken) {
          setEssBccAccounts(essAccountId, accessToken.id)
        }
      },
      [setEssBccAccounts, userDataLib]
    )

    const setupStatus = useCallback(() => {
      if (!state.accessTokenSetupComplete) {
        if (productLib.hasETSProduct(accessTokenId as string) || accessTokenId === config.NO_TOKEN_TOKEN) {
          setState({
            accessTokenSetupComplete: true,
            accessTokenSetupLoading: false
          })
        } else if (!productLib.hasForensicsProduct(accessTokenId as string)) {
          setState({
            isLoading: false
          })
        }
      }
    }, [state.accessTokenSetupComplete, productLib])

    // updateShowConfirmAddProduct
    const updateShowConfirmAddProduct = useCallback(
      (id: AccessToken | undefined) => {
        setState({
          setShowConfirmAddProduct: false
        })

        if (id && id !== config.NO_TOKEN_TOKEN) {
          setState({
            showConfirmAddProduct: productLib.hasSentinelNoForensics(id as any)
          })
        }

        if (state.showConfirmAddProduct) {
          setState({
            accessTokenSetupLoading: false
          })
        } else {
          setupStatus()
        }
        setState({
          confirmAddProductLoading: false
        })
        return state.showConfirmAddProduct
      },
      [setupStatus, state.showConfirmAddProduct, productLib]
    )

    // goToRemediation
    const goToRemediation = useCallback(
      /* eslint-disable react/prop-types */
      (id: string, clearNewIncidentData = false) => {
        dispatch(
          /**
           * this will only initialize split when the user first logs in
           * if the user switches tenants or logs out and back in we use the same split store from the initial login
           * if the user refreshes the page it will re-initialize split
           * */
          initSplitio({
            accessTokenId: accessTokenId as string,
            account: userDataLib.getAccountByAccessToken(accessTokenId as string),
            cb: () => {
              if (!updateShowConfirmAddProduct(accessToken as AccessToken)) {
                if (
                  (accessToken === config.NO_TOKEN_TOKEN || clearNewIncidentData === true) &&
                  location &&
                  location.search
                ) {
                  // eslint-disable-next-line no-param-reassign
                  location.search = ''
                }
                routesConfig.REMEDIATION.goto()
              }

              setState({ isLoading: false })
            }
          }) as any
        )
      },
      [dispatch, updateShowConfirmAddProduct, location, userDataLib]
      /* eslint-enable react/prop-types */
    )

    // checkEssAccount
    const doCheckEssAccount = useCallback(() => {
      if (state.isLoading) {
        if (isEssAccountSuccess) {
          if (essAccount.accountId) {
            const essAccountId = essAccount.accountId

            dispatch(setUserEssRegion({ essRegion: essAccount.region || 'US' }))

            setBaseVariables(essAccountId)
            goToRemediation((accessToken || {}).id as string)
          } else {
            setBaseVariables()
            goToRemediation(accessTokenId as string)
          }
        }

        if (isEssAccountFailed) {
          setBaseVariables()
          goToRemediation(accessTokenId as string, true)
        }
      }
    }, [
      essAccount.accountId,
      essAccount.region,
      dispatch,
      setBaseVariables,
      goToRemediation,
      state.isLoading,
      isEssAccountFailed,
      isEssAccountSuccess
    ])

    useEffect(() => {
      if ((!isEssAccountAlreadyChecked && isEssAccountSuccess) || isEssAccountFailed) {
        doCheckEssAccount()
        isEssAccountAlreadyChecked = true
      }
    }, [isEssAccountFailed, isEssAccountSuccess, remediation, doCheckEssAccount])

    const checkEssAccount = useCallback(() => {
      dispatch(forensicsGetEssAccount({ accessTokenId: accessTokenId as string }))
    }, [dispatch])

    const doCheckBCSSubscription = useCallback(() => {
      const accounts = userDataLib.getAccounts()

      // Check account accessTokens without ETS product and bcsEntitled
      const accountsToCheck = accounts.reduce((acc: BCSSubscriptionAccount[], account: Account) => {
        if (account.bcsEntitled) {
          return [
            ...acc,
            {
              accountId: account.accountId,
              bccId: account.bccId,
              bcsEntitled: account.bcsEntitled,
              accessTokens: account.accessTokens.filter((token: AccessToken) =>
                token.products.some((product: Products) => product !== config.PRODUCTS.ETS)
              )
            }
          ]
        }

        return acc
      }, [])

      // Only make BCS check call for tokens with Sentinel or FIR
      if (accountsToCheck.length) {
        accountsToCheck.forEach(account => {
          account.accessTokens.forEach((token: AccessToken) => {
            dispatch(
              checkBCSSubscription({
                accessToken: token,
                accountId: account.accountId,
                bccId: account.bccId,
                bcsEntitled: account.bcsEntitled
              })
            )
          })
        })
      }
    }, [dispatch, userDataLib])

    useEffect(() => {
      if (isAccessTokenSet) {
        doCheckBCSSubscription()
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAccessTokenSet])

    // Init, set accessTokenId
    /* eslint-disable react/prop-types */
    const init = useCallback(() => {
      if (accessTokenLib.isGuestAccess()) {
        dispatch(setShareSecret({ value: urlParams.shareSecret || '' }))
      } else {
        // try to pull accessTokenId from query params if they exist
        let accessTokenSearch: string | null = null
        if (location && location.search) {
          const searchParams: SearchParams = queryString.parse(location.search)
          if (searchParams.accessToken) {
            accessTokenSearch = searchParams.accessToken
          } else if (searchParams.bccId) {
            accessTokenSearch = accessTokenLib.getDefaultBccAccountAccessTokenId(
              searchParams.bccId,
              config.PRODUCTS.FORENSICS
            )
          }
        }
        accessTokenId = accessTokenSearch || urlParams.accessToken || null

        if (accessTokenId) {
          dispatch(setCurrentAccessToken(accessTokenId))
          checkEssAccount()
        }
        setupStatus()
      }
    }, [accessTokenLib, dispatch, urlParams.accessToken, urlParams.shareSecret, location, checkEssAccount, setupStatus])
    /* eslint-enable react/prop-types */

    useEffect(() => {
      if (!isAlreadyInit && isEqual(state, INITIAL_STATE)) {
        init()
        isAlreadyInit = true
      }
    }, [init, state])

    // Checks to see if the current accessToken has ETS, if it does display the Missing Requirements page
    // If the current user is not entitled to FIR for the token's account, it displays the Missing Requirements page
    // If the current accessToken does not have FIR, it displays the Start Trial page
    // If the current accessToken is bootstrapping go to Onboarding page
    // Else validate the current accessToken can reach the page it's trying to load
    useEffect(() => {
      if (urlParams.accessToken) {
        if (productLib.hasMissingRequirement(urlParams.accessToken as string)) {
          routesConfig.MISSING_REQUIREMENTS.goto({ type: 'o365' })
        } else if (!accessTokenLib.hasForensicsEntitlement(urlParams.accessToken as string)) {
          routesConfig.MISSING_REQUIREMENTS.goto({ type: 'entitlements' })
        } else if (!productLib.hasForensicsProduct(urlParams.accessToken as string)) {
          // check if we need to show the start trial page
          dispatch(setCurrentAccessToken(urlParams?.accessToken))
          routesConfig.START_TRIAL.goto()
        } else if (
          isAccessTokenSet &&
          (accessTokenStore?.settings.forensicsBootstrapProgress === SettingsValue.on ||
            accessTokenStore?.settings.ForensicsOnboardingInQueue === SettingsValue.on)
        ) {
          // Only show Onboarding page if bootstrapping is in Progress or bootstrapping in queue
          routesConfig.ONBOARDING.goto()
        } else {
          // After the splitio sdk is initiatied via initSplitio the isReady store value is set true
          // allowing getTreatments to be called without error. On each change of the access token,
          // get the account-specific treatments from Split.
          if (isSplitioReady) {
            dispatch(
              getTreatments({
                splitNames: Object.values(FEATURES),
                key: userDataLib.getAccountByAccessToken(urlParams.accessToken)?.accountId
              })
            )
          }
          validateAppForAccessToken(urlParams.accessToken)
        }
      }
      // Need this rule because accessToken is not set when report is first initialized
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, urlParams.accessToken, productLib])

    // reset
    const reset = useCallback(() => {
      isAlreadyInit = false
      isEssAccountAlreadyChecked = false
      accessTokenId = null
      accessToken = null
      setState({ INITIAL_STATE })
    }, [])

    // wrappedProps
    const wrappedProps: ReportProps = useMemo(
      () => ({
        reset,
        ...state
      }),
      [reset, state]
    )

    return useMemo(() => {
      if (state.isLoading) {
        return <LinearProgress />
      }

      return <WrappedComponent {...(props as any)} report={wrappedProps} />
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.isLoading])
  }

  return ReportLogic
}
