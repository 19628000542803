import React from 'react'

import { Button, Grid, Tooltip } from '@barracuda-internal/bds-core'

import Alert, { AlertProps } from 'global/components/lib/alerts/Alert'
import { useFormatMessage } from 'global/lib/localization'

import CreatedIncidentsDashboard, {
  CreatedIncidentsDashboardProps
} from 'fir/components/pages/remediation/dashboards/CreatedIncidentsDashboard'
import CTABanner from 'fir/components/lib/ctaBanner/CTABanner'
import IncidentsTable, { IncidentsTableProps } from 'fir/components/pages/remediation/incidentsTable/IncidentsTable'
import NewIncidentDialog, { NewIncidentDialogProps } from 'fir/components/lib/newIncidentDialog/NewIncidentDialog'
import { REMEDIATION_CTA_COOKIE } from 'fir/components/lib/ctaBanner/useCTABannerLogic'
import remediationLogic from 'fir/components/pages/remediation/RemediationLogic'
import Layout from 'fir/components/lib/layout/Layout'

import RemediatedThreatsDashboard, { RemediatedThreatsDashboardProps } from './dashboards/RemediatedThreatsDashboard'
import TopAttackedUsersDashboard, { TopAttackedUsersDashboardProps } from './dashboards/TopAttackedUsersDashboard'
import styles from './remediationStyles'

const BASE_I18N_KEY = 'fir.app.remediation'

interface RemediationProps {
  basicIncidentResponse: boolean
  hideCTA: boolean
  incidentTableConfig: IncidentsTableProps
  incidentsCreatedDashboardConfig: CreatedIncidentsDashboardProps
  newIncidentDialogConfig: NewIncidentDialogProps
  openNewIncidentDialog: () => void
  pageAlertConfig: AlertProps
  remediatedThreatsDashboardConfig: RemediatedThreatsDashboardProps
  showIncidentsPageError: boolean
  topUsersDashboardConfig: TopAttackedUsersDashboardProps
}

const Remediation: React.FC<any> = ({
  basicIncidentResponse,
  hideCTA,
  incidentTableConfig,
  incidentsCreatedDashboardConfig,
  newIncidentDialogConfig,
  openNewIncidentDialog,
  pageAlertConfig,
  remediatedThreatsDashboardConfig,
  showIncidentsPageError,
  topUsersDashboardConfig
}: RemediationProps) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const tooltipText = (
    <div>
      <p>{formatMessage('button.tooltip', { b: (txt: any) => <b key={txt}>{txt}</b> })}</p>
    </div>
  )

  const createIncidentButton = (
    <Tooltip interactive title={tooltipText} disableHoverListener={!basicIncidentResponse}>
      <span>
        <Button
          className={classes.createIncidentButton}
          onClick={() => {
            openNewIncidentDialog()
          }}
          color="primary"
          variant="contained"
          data-action="signup"
          disabled={basicIncidentResponse}
        >
          {formatMessage('button.create_incident')}
        </Button>
      </span>
    </Tooltip>
  )

  return (
    <Layout
      pageTitle={formatMessage('labels.page_title')}
      pageSubtitle={formatMessage('labels.page_subtitle')}
      rightContent={createIncidentButton}
    >
      {newIncidentDialogConfig.isOpened && <NewIncidentDialog {...newIncidentDialogConfig} />}
      {!hideCTA && <CTABanner ctaCookie={REMEDIATION_CTA_COOKIE} />}
      {showIncidentsPageError && <Alert {...pageAlertConfig} />}
      <Grid container className={classes.dashboards} direction="row" spacing={2}>
        <Grid xs={4} item>
          <CreatedIncidentsDashboard {...incidentsCreatedDashboardConfig} />
        </Grid>
        <Grid xs={4} item>
          <RemediatedThreatsDashboard {...remediatedThreatsDashboardConfig} />
        </Grid>
        <Grid xs={4} item>
          <TopAttackedUsersDashboard {...topUsersDashboardConfig} />
        </Grid>
      </Grid>
      <IncidentsTable {...incidentTableConfig} />
    </Layout>
  )
}

export default remediationLogic(Remediation)
