import React, { useMemo } from 'react'

import { Box, Grid, Link, Paper, Switch, Typography } from '@barracuda-internal/bds-core'

import Alert from 'global/components/lib/alerts/Alert'
import Breadcrumbs from 'global/components/lib/breadcrumbs/Breadcrumbs'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import Tabs from 'global/components/lib/Tabs/Tabs'
import TagInput from 'global/components/lib/tagInput/TagInput'
import { useFormatMessage } from 'global/lib/localization'
import { IncidentDetailsSource } from 'global/types/api/newIncident'

import AdditionalActionsDialog from 'fir/components/lib/additionalActionsDialog/AdditionalActionsDialog'
import Layout from 'fir/components/lib/layout/Layout'

import useIncidentDetailsLogic, {
  EMAILS,
  USERS,
  THREATS,
  CLICKED_LINKS,
  IncidentDetailsProps
} from './useIncidentDetailsLogic'
import styles from './IncidentDetailsStyles'
import EmailsByIncident from './emailsByIncident/EmailsByIncident'
import ThreatsByIncident from './threatsByIncident/threatsByIncident'
import UsersDetailsByIncident from './userDetailsByIncident/UsersDetailsByIncident'
import ClickedLinksByIncident from './clickedLinksByIncident/ClickedLinksByIncident'

const BASE_I18N_KEY = 'fir.app.remediation.incident_details'

const IncidentDetails: React.FC<IncidentDetailsProps> = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [incidentDetailsLogic] = useIncidentDetailsLogic()

  return useMemo(() => {
    const {
      additionalActionsDialogConfig,
      breadcrumbList,
      handleContinuousRemediationChange,
      hasRemediationActions,
      incidentDetailsData,
      isContinuousRemediationEnabled,
      isContinuousRemediationLoading,
      isDeleteInProgress,
      isPageInProgress,
      onViewWorkflowDetails,
      openFollowUpDialog,
      pageAlertConfig,
      pageError,
      tabsConfig,
      tagInputConfig
    } = incidentDetailsLogic

    return (
      <Layout pageTitle={formatMessage('labels.title')}>
        {isPageInProgress ? (
          <LinearProgress />
        ) : (
          <>
            {additionalActionsDialogConfig.isOpened && <AdditionalActionsDialog {...additionalActionsDialogConfig} />}
            <div className={classes.breadcrumb}>
              <Breadcrumbs list={breadcrumbList} />
            </div>
            {pageError && <Alert {...pageAlertConfig} />}

            <Box display="flex" p={0}>
              <Box className={classes.detailsWrapper} order={1} sx={{ width: '60%' }}>
                <Paper className={classes.detailsCell} elevation={1}>
                  <div className={classes.header}>
                    <div className={classes.cellHeader}>
                      <Typography className={classes.cellTitle} variant="h5">
                        {formatMessage('labels.incidentDetails')}
                      </Typography>
                      <Typography variant="subtitle1">{formatMessage('labels.incidentDetailsSubtitle')}</Typography>
                    </div>
                    <Grid container className={classes.details} spacing={1}>
                      <Grid item xs={3}>
                        <Typography>{formatMessage('summary.reported')}</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        {incidentDetailsData.createdOn}
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>{formatMessage('summary.createdBy')}</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        {incidentDetailsData.createdBy}
                        {incidentDetailsData.createdByName &&
                          incidentDetailsData.incidentDetails.source === IncidentDetailsSource.automatedWorkflow && (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <Link
                              href=""
                              onClick={() => {
                                onViewWorkflowDetails(incidentDetailsData.incidentDetails.subSource)
                              }}
                            >
                              <span className={classes.bold}>{incidentDetailsData.createdByName}</span>
                            </Link>
                          )}
                        {incidentDetailsData.createdByName &&
                          incidentDetailsData.incidentDetails.source !== IncidentDetailsSource.automatedWorkflow && (
                            <span className={classes.bold}>{incidentDetailsData.createdByName}</span>
                          )}
                        {!incidentDetailsData.createdBy && !incidentDetailsData.createdByName && 'N/A'}
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>{formatMessage('summary.impact')}</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        {formatMessage('summary.values.messagesAffected', {
                          messagesCount: (
                            <span key="messages-received" className={classes.bold}>
                              {incidentDetailsData.messagesReceived || 0}
                            </span>
                          ),
                          internalUsersCount: (
                            <span key="internal-users" className={classes.bold}>
                              {incidentDetailsData.distinctInternalRecipientCount || 0}
                            </span>
                          ),
                          externalUsersCount: (
                            <span key="external-users" className={classes.bold}>
                              {incidentDetailsData.distinctExternalRecipientCount || 0}
                            </span>
                          )
                        })}
                      </Grid>
                      {(incidentDetailsData.remediationActions.delete ||
                        incidentDetailsData.remediationActions.quarantine) && (
                        <>
                          <Grid item xs={3}>
                            <Typography className={classes.creLabel}>
                              {formatMessage('summary.continuousRemediation')}
                            </Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <div>
                              {isContinuousRemediationLoading ? (
                                <div className={classes.creLinearWrapper}>
                                  <LinearProgress />
                                </div>
                              ) : (
                                <>
                                  <Switch
                                    color="primary"
                                    onChange={handleContinuousRemediationChange}
                                    checked={isContinuousRemediationEnabled}
                                  />
                                  {isContinuousRemediationEnabled && (
                                    <span key="cre-enabled" className={classes.creDetails}>
                                      {formatMessage('summary.values.activeUntil', {
                                        date: (
                                          <span key="cre-enabled-date" className={classes.bold}>
                                            {incidentDetailsData.continuousRemediationUntil}
                                          </span>
                                        )
                                      })}
                                    </span>
                                  )}
                                </>
                              )}
                            </div>
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <div className={classes.cellSubHeader}>
                      <Typography className={classes.cellTitle} variant="h5">
                        {formatMessage('searchCriteria.title')}
                      </Typography>
                    </div>
                    <Grid container className={classes.details} spacing={1}>
                      <Grid item xs={3}>
                        <Typography>{formatMessage('searchCriteria.sender')}</Typography>
                      </Grid>
                      <Grid item xs={9} className={classes.ellipsis}>
                        {incidentDetailsData.senderName && incidentDetailsData.senderName}
                        {incidentDetailsData.senderEmail && <> &lt;{incidentDetailsData.senderEmail}&gt;</>}
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>{formatMessage('searchCriteria.subject')}</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        {incidentDetailsData.subject}
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>Message body text:</Typography>
                      </Grid>
                      <Grid item xs={9} className={classes.ellipsis}>
                        {incidentDetailsData.bodyText}
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>Message body URL:</Typography>
                      </Grid>
                      <Grid item xs={9} className={classes.ellipsis}>
                        {incidentDetailsData.bodyLinks}
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>{formatMessage('searchCriteria.attachment')}</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        {incidentDetailsData.attachment}
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              </Box>
              <Box className={classes.detailsWrapper} order={2} sx={{ width: '40%' }}>
                <Paper className={classes.detailsCell} elevation={1}>
                  <div className={classes.header}>
                    <div className={classes.cellHeader}>
                      <Typography className={classes.cellTitle} variant="h5">
                        {formatMessage('labels.remediationAction')}
                      </Typography>
                      <Typography variant="subtitle1">{formatMessage('labels.remediationActionSubtitle')}</Typography>
                    </div>
                    <ul className={classes.actionsList}>
                      {incidentDetailsData.createdByName === 'Insights-Automated' && (
                        <li>{formatMessage('remediationAction.values.automaticRemediation')}</li>
                      )}
                      {incidentDetailsData.remediationActions.quarantine && (
                        <li>
                          {formatMessage('remediationAction.values.quarantined', {
                            count: (
                              <span key="quarantined-total" className={classes.bold}>
                                {incidentDetailsData.distinctInternalRecipientCount}
                              </span>
                            )
                          })}
                          <ul>
                            <li>
                              {formatMessage('remediationAction.values.quarantinedBreakdown', {
                                count: (
                                  <span key="quarantined-count" className={classes.bold}>
                                    {incidentDetailsData.remediatedEmailCount}
                                  </span>
                                ),
                                creCount: (
                                  <span key="cre-quarantined-count" className={classes.bold}>
                                    {incidentDetailsData.continuousRemediationCount}
                                  </span>
                                )
                              })}
                            </li>
                          </ul>
                        </li>
                      )}
                      {incidentDetailsData.remediationActions.delete && (
                        <li>
                          {formatMessage(
                            `${
                              isDeleteInProgress
                                ? 'remediationAction.values.delete_in_progress'
                                : 'remediationAction.values.deleted'
                            }`,
                            {
                              count: (
                                <span key="deleted-total" className={classes.bold}>
                                  {incidentDetailsData.distinctInternalRecipientCount}
                                </span>
                              )
                            }
                          )}
                          <ul>
                            <li>
                              {formatMessage('remediationAction.values.deletedBreakdown', {
                                count: (
                                  <span key="deleted-count" className={classes.bold}>
                                    {incidentDetailsData.remediatedEmailCount}
                                  </span>
                                ),
                                creCount: (
                                  <span key="cre-deleted-count" className={classes.bold}>
                                    {incidentDetailsData.continuousRemediationCount}
                                  </span>
                                )
                              })}
                            </li>
                          </ul>
                        </li>
                      )}
                      {incidentDetailsData.remediationActions.notify && (
                        <li>
                          {formatMessage('remediationAction.values.notified', {
                            count: (
                              <span key="notified" className={classes.bold}>
                                {incidentDetailsData.distinctInternalRecipientCount}
                              </span>
                            )
                          })}
                        </li>
                      )}
                      {(!!incidentDetailsData.senderPolicies.blocked.length ||
                        !!incidentDetailsData.senderPolicies.quarantined.length) && (
                        <li>
                          {formatMessage('remediationAction.values.senderPolicies', {
                            count: (
                              <span key="sender-policies-blocked" className={classes.bold}>
                                {incidentDetailsData.senderPolicies.blocked.length ||
                                  incidentDetailsData.senderPolicies.quarantined.length}
                              </span>
                            ),
                            action: (
                              <span key="sender-policies-type" className={classes.textLowercase}>
                                {formatMessage(
                                  `remediationAction.values.${
                                    incidentDetailsData.senderPolicies.blocked.length
                                      ? 'senderPoliciesBlocked'
                                      : 'senderPoliciesQuarantined'
                                  }`
                                )}
                              </span>
                            ),
                            type: formatMessage(
                              `remediationAction.values.${
                                incidentDetailsData.remediationActions.addSenderPolicy
                                  ? 'senderPoliciesSenders'
                                  : 'senderPoliciesDomains'
                              }`
                            )
                          })}
                        </li>
                      )}
                      {incidentDetailsData.remediationActions.addBCSPolicy && (
                        <li>
                          {formatMessage('remediationAction.values.bcsPolicies', {
                            count: (
                              <span key="bcs-policies" className={classes.bold}>
                                {incidentDetailsData.maliciousDomains.length}
                              </span>
                            )
                          })}
                        </li>
                      )}
                      {!hasRemediationActions && <li>{formatMessage('remediationAction.values.noActionsTaken')}</li>}
                    </ul>
                    <div>
                      <Typography>{formatMessage('labels.tags')}</Typography>
                      <TagInput {...tagInputConfig} />
                    </div>
                    <div
                      className={classes.link}
                      role="presentation"
                      data-action="open-additional-actions"
                      onClick={openFollowUpDialog}
                    >
                      {formatMessage('link.additional_recommendations')}
                    </div>
                  </div>
                </Paper>
              </Box>
            </Box>
            <Tabs {...tabsConfig} />
            {tabsConfig.activeTab === EMAILS && <EmailsByIncident />}
            {tabsConfig.activeTab === USERS && <UsersDetailsByIncident />}
            {tabsConfig.activeTab === CLICKED_LINKS && <ClickedLinksByIncident />}
            {tabsConfig.activeTab === THREATS && <ThreatsByIncident />}
          </>
        )}
      </Layout>
    )
  }, [classes, formatMessage, incidentDetailsLogic])
}

export default IncidentDetails
