import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  dashboards: {
    padding: theme.spacing(2, 2, 0, 2),
    width: '100%'
  },
  floatLeft: {
    float: 'left'
  },
  floatRight: {
    float: 'right'
  },
  header: {
    margin: theme.spacing(2),
    position: 'relative',
    zIndex: 1
  },
  pageAlert: {
    margin: theme.spacing(0, 2, 3, 2)
  },
  root: {
    display: 'block',
    position: 'relative'
  },
  stateButton: {
    margin: theme.spacing(1)
  },
  warningIcon: {
    height: '24px',
    padding: '5px 8px 0px 0px'
  },
  warningIconContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  wrapper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 2,
    margin: theme.spacing(2, 2, 3, 2),
    overflow: 'hidden',
    position: 'relative'
  }
}))
