import { makeStyles } from '@material-ui/core/styles'

import { NAV_BAR_HEIGHT } from 'global/components/lib/layout/layoutStyles'

export default makeStyles(theme => ({
  container: {
    overflow: 'hidden',
    height: `calc(100vh - ${NAV_BAR_HEIGHT}px)`,
    borderTop: `1px solid ${theme.palette.divider}`
  },
  linearProgress: {
    position: 'fixed',
    right: 0,
    left: 0,
    top: NAV_BAR_HEIGHT,
    backgroundColor: 'transparent',
    zIndex: 3000
  },
  sideNav: {
    borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    height: '100%',
    overflowY: 'auto'
  },
  reportPage: {
    height: '100%',
    overflowY: 'auto'
  }
}))
