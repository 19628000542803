import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { generateUserDataLib, UserDataLib } from 'global/lib/userData/userData'

export default function useUserData(): UserDataLib[] {
  const { userStore }: any = useSelector((_stores: any) => ({
    userStore: _stores.user || {}
  }))

  const lib = useMemo(() => {
    return generateUserDataLib(() => userStore)
  }, [userStore])

  return [lib, userStore]
}
