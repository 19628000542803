import React, { useMemo } from 'react'

import { Link } from '@barracuda-internal/bds-core'

import Alert from 'global/components/lib/alerts/Alert'
import { config } from 'global/lib/config'
import { useFormatMessage } from 'global/lib/localization'

const I18N_KEY = 'app.maintenance_banner'

const BASE_I18N_KEY = I18N_KEY

const MaintenanceBanner: React.FC = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const alertContent = useMemo(
    () =>
      formatMessage('content', {
        a: (txt: any) => (
          <Link
            key="link"
            target="_blank"
            href={config.LINKS.SUPPORT}
            style={{ color: '#D32F2F', fontWeight: 'bold', textDecoration: 'underline' }}
          >
            {txt}
          </Link>
        )
      }),
    [formatMessage]
  )

  return useMemo(() => {
    if (!config.shouldShowMaintenanceBanner) {
      return null
    }

    return <Alert icon={false} variant="standard" pageAlert={false} alertContent={alertContent} />
  }, [alertContent])
}

export default MaintenanceBanner
