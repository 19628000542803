import React, { useCallback, useMemo, useReducer, useEffect } from 'react'

import { Duration } from 'luxon'
import { IconButtonProps, MenuProps } from '@barracuda-internal/bds-core'

import { formatDateWithTime, humanizeDuration, timeDifference, isAfterDate } from 'global/lib/datetime'
import useUserDataLib from 'global/lib/userData/useUserData'
import useAccessTokenLib from 'global/lib/accessToken/useAccessToken'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'
import { setError, resetError } from 'global/redux/features/report/reportSlice'
import {
  getScanStats,
  getScanThreatLog,
  resetScanStats,
  reset as resetScan
} from 'global/redux/features/scan/scanSlice'
import { isPending, isSuccess } from 'global/redux/toolkit/api'
import { ScanStats, ThreatLog } from 'global/types/api/scan'
import { Account } from 'global/types/api/accountType'

import {
  deleteEtsReport,
  activateReportSharing,
  deactivateReportSharing,
  rerunCurrentScan
} from 'ets/redux/features/settings/settingsSlice'
import { ColumnsConfig } from 'ets/redux/types/dataTables'
import { useAppDispatch, useAppSelector } from 'ets/redux/toolkit/hooks'

export interface ModifiedScanStats extends ScanStats {
  name: string | undefined
  triggeredOn: string
  finishedOn: string
  duration: string
  threatsDetected: number
  remaining: string
  percentageCompleted: number
}

export interface OptionsMenu {
  id: string
  disabled: boolean
  action: () => void
}

export interface DashboardHeaderLogic {
  moreInfoButtonMenuOptions: {
    iconButtonProps: IconButtonProps
    menuProps: MenuProps
    menuItems: OptionsMenu[]
  }
  sendShareTrackingEvent: () => void
  isTimeDurationDisabled: boolean
  columnsConfig: ColumnsConfig
  getReportShareLink: () => string
  getShareLinkExpiration: () => string
  isDeleteDialogOpened: boolean
  isDeleteInProgress: boolean
  isNewScanDialogOpened: boolean
  isNewScanInProgress: boolean
  isShareChecked: boolean
  isShareInProgress: boolean
  onDeleteScan: () => void
  onNewScan: () => void
  onShare: () => void
  onToggleDeleteDialog: () => void
  onToggleNewScanDialog: () => void
  scan: ModifiedScanStats | undefined
  scanStatus: string
  shouldRenderScanDetails: boolean
  threatLog: ThreatLog[]
  isButtonDisabled: boolean
  GRID_COLUMNS: {
    [key: string]: string
  }
}

export type State = {
  scannedId: string | undefined
  anchorOptionsMenu: React.SyntheticEvent['currentTarget'] | undefined
  lastSpAttackCount: number | undefined
}

export const SCAN_STATUSES = {
  PREPARING: 'scan_preparing',
  IN_PROGRESS: 'scan_in_progress',
  COMPLETED: 'scan_completed'
}

const STATUS_REFRESH_INTERVAL = 10000
let checkScanStatus: number | undefined

const BASE_I18N_ERROR_KEY = 'ets.app.error'

export default function useDashboardHeaderLogic(): [DashboardHeaderLogic] {
  const dispatch = useAppDispatch()
  const {
    accessToken,
    scan,
    threatEmailsTable,
    isDeleteInProgress,
    isNewScanInProgress,
    isShareInProgress,
    isShareValid,
    userEmail,
    isShareSecretSet
  } = useAppSelector(_stores => ({
    accessToken: _stores.accessToken?.accessToken,
    scan: _stores.scan,
    threatEmailsTable: _stores.dataTables.threatEmails,
    isDeleteInProgress: isPending(_stores.settings.deleteEtsReportApiStatus),
    isNewScanInProgress: isPending(_stores.settings.rerunCurrentScanApiStatus),
    isShareInProgress:
      isPending(_stores.settings.activateReportSharingApiStatus) ||
      isPending(_stores.settings.deactivateReportSharingApiStatus),
    userEmail: _stores.user.data.email || '',
    isShareSecretSet: !!_stores.accessToken.shareSecret?.value,
    isShareValid: isAfterDate({ initialDate: _stores.accessToken.accessToken?.reportSecret?.expires })
  }))
  const [state, setState] = useReducer((_state: State, newState: Partial<State>) => ({ ..._state, ...newState }), {
    scannedId: undefined,
    anchorOptionsMenu: undefined,
    lastSpAttackCount: undefined
  })
  const [accessTokenLib] = useAccessTokenLib()
  const [userDataLib] = useUserDataLib()
  const [isDeleteDialogOpened, toggleDeleteDialog] = useDialogLogic()
  const [isNewScanDialogOpened, toggleNewScanDialog] = useDialogLogic()
  // Remove to show progress again once the backend work is complete
  const isTimeDurationDisabled = true

  const isAccessTokenSecretActive = useMemo(() => {
    return !!accessToken?.reportSecret?.active && !!isShareValid
  }, [accessToken, isShareValid])

  // init
  useEffect(() => {
    return () => {
      clearInterval(checkScanStatus)
      dispatch(resetScan())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // accessToken is Changed
  useEffect(() => {
    if (!!state.scannedId && state.scannedId !== accessToken?.id) {
      clearInterval(checkScanStatus)
      dispatch(getScanStats())
      dispatch(getScanThreatLog())
    }
  }, [state.scannedId, accessToken, dispatch])

  // scan-status check is completed
  useEffect(() => {
    if (isSuccess(scan.scanStatsApiStatus)) {
      if (scan.stats.spAttackCount !== state.lastSpAttackCount) {
        setState({ lastSpAttackCount: scan.stats.spAttackCount })

        if (state.lastSpAttackCount !== undefined) {
          dispatch(getScanThreatLog())
        }
      }

      if (state.scannedId !== accessToken?.id) {
        setState({ scannedId: accessToken?.id })
      }

      if (!scan.stats.inProgress) {
        clearInterval(checkScanStatus)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scan.scanStatsApiStatus])

  // refresh scan-status if necessary
  useEffect(() => {
    if (isSuccess(scan.scanStatsApiStatus) && scan.stats.inProgress) {
      clearInterval(checkScanStatus)

      if (scan.stats.inProgress) {
        dispatch(resetScanStats())

        checkScanStatus = window.setInterval(() => {
          dispatch(getScanStats())
        }, STATUS_REFRESH_INTERVAL)
      }
    }
  }, [scan.scanStatsApiStatus, scan.stats.inProgress, dispatch])

  const onDeleteScan = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.DELETE_REPORT, { accessToken: accessToken?.id })
    dispatch(deleteEtsReport())
  }, [dispatch, accessToken])

  const onNewScan = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.NEW_SCAN, { accessToken: accessToken?.id })
    dispatch(resetError())

    if (accessToken) {
      const { accountId } = userDataLib.getAccountByAccessToken(accessToken.id) || ({} as Account)
      dispatch(rerunCurrentScan({ accessTokenId: accessToken.id, accountId, email: userEmail }))
    }

    dispatch(setError(`${BASE_I18N_ERROR_KEY}.unable_to_load_scan`))
  }, [accessToken, userEmail, dispatch, userDataLib])

  const onCloseMoreOptionsMenu = useCallback(() => {
    setState({ anchorOptionsMenu: undefined })
  }, [])

  const onShare = useCallback(() => {
    if (isAccessTokenSecretActive) {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.SHARE_LINK_DISABLED, { accessToken: accessToken?.id })
      dispatch(deactivateReportSharing())
    } else {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.SHARE_LINK_ENABLED, { accessToken: accessToken?.id })
      dispatch(activateReportSharing())
    }
  }, [dispatch, isAccessTokenSecretActive, accessToken])

  const sendShareTrackingEvent = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.SHARE_REPORT, { accessToken: accessToken?.id })
  }, [accessToken])

  const getReportShareLink = useCallback(() => {
    return isAccessTokenSecretActive ? accessTokenLib.getAccessTokenShareLink(accessToken?.id) : ''
  }, [isAccessTokenSecretActive, accessTokenLib, accessToken])

  const getShareLinkExpiration = useCallback(() => {
    return isAccessTokenSecretActive ? accessTokenLib.getAccessTokenSecretExpires(accessToken?.id) : ''
  }, [isAccessTokenSecretActive, accessTokenLib, accessToken])

  const scanData: ModifiedScanStats | undefined = useMemo(() => {
    const { stats } = scan

    if (!stats.id) {
      return undefined
    }

    const spentTime = Math.round(timeDifference({ subtractedDate: stats.createdOn }).as('minute'))
    const totalTime = spentTime + stats.scanTimeRemaining

    return {
      ...stats,
      name: accessToken?.name,
      triggeredOn: formatDateWithTime(stats.createdOn),
      finishedOn: formatDateWithTime(stats.finishedOn),
      duration: humanizeDuration(
        timeDifference({ initialDate: stats.finishedOn, subtractedDate: stats.createdOn }).milliseconds
      ),
      threatsDetected: stats.spAttackCount,
      remaining: humanizeDuration(Duration.fromObject({ minutes: stats.scanTimeRemaining }).as('millisecond')),
      percentageCompleted: stats.scanTimeRemaining ? Math.floor((spentTime / totalTime) * 100) : 0
    }
  }, [scan, accessToken])

  const scanStatus = useMemo(() => {
    if (!scanData?.scanTimeRemaining) {
      return SCAN_STATUSES.PREPARING
    }
    if (scanData?.inProgress) {
      return SCAN_STATUSES.IN_PROGRESS
    }

    return SCAN_STATUSES.COMPLETED
  }, [scanData])

  const shouldRenderScanDetails = useMemo(() => {
    return scan.isInitialScanStatsLoaded
  }, [scan.isInitialScanStatsLoaded])

  const onToggleDeleteDialog = useCallback(() => {
    if (!isDeleteInProgress) {
      toggleDeleteDialog()
    }
  }, [isDeleteInProgress, toggleDeleteDialog])

  const onToggleNewScanDialog = useCallback(() => {
    if (!isNewScanInProgress) {
      toggleNewScanDialog()
    }
  }, [isNewScanInProgress, toggleNewScanDialog])

  const moreInfoButtonMenuOptions = useMemo(() => {
    return {
      iconButtonProps: {
        onClick: (e: React.SyntheticEvent) => {
          setState({ anchorOptionsMenu: e.currentTarget })
        }
      } as IconButtonProps,
      menuProps: {
        anchorEl: state.anchorOptionsMenu,
        open: Boolean(state.anchorOptionsMenu),
        onClose: onCloseMoreOptionsMenu
      } as MenuProps,
      menuItems: [
        {
          id: 'new_scan',
          disabled: scanData?.inProgress || !scanData?.id,
          action: () => {
            onCloseMoreOptionsMenu()
            onToggleNewScanDialog()
          }
        },
        {
          id: 'delete_scan',
          disabled: scanData?.inProgress || !scanData?.id,
          action: () => {
            onCloseMoreOptionsMenu()
            onToggleDeleteDialog()
          }
        }
      ] as OptionsMenu[]
    }
  }, [onToggleNewScanDialog, onToggleDeleteDialog, scanData, state, onCloseMoreOptionsMenu])

  const isButtonDisabled = useMemo(() => {
    return scanData?.inProgress || scanStatus === SCAN_STATUSES.PREPARING || !scanData?.id || isShareSecretSet
  }, [scanData, scanStatus, isShareSecretSet])

  return useMemo(
    () => [
      {
        onToggleNewScanDialog,
        onToggleDeleteDialog,
        isDeleteDialogOpened,
        isNewScanDialogOpened,
        isDeleteInProgress,
        isNewScanInProgress,
        onDeleteScan,
        onShare,
        onNewScan,
        isShareChecked: isAccessTokenSecretActive,
        isShareInProgress,
        getReportShareLink,
        getShareLinkExpiration,
        moreInfoButtonMenuOptions,
        scan: scanData,
        threatLog: scan.threatLog,
        shouldRenderScanDetails,
        scanStatus,
        GRID_COLUMNS: threatEmailsTable.GRID_COLUMNS,
        columnsConfig: threatEmailsTable.columnsConfig,
        isButtonDisabled,
        sendShareTrackingEvent,
        isTimeDurationDisabled
      }
    ],
    [
      onToggleNewScanDialog,
      onToggleDeleteDialog,
      isDeleteDialogOpened,
      isNewScanDialogOpened,
      isDeleteInProgress,
      isNewScanInProgress,
      onDeleteScan,
      onShare,
      onNewScan,
      isAccessTokenSecretActive,
      isShareInProgress,
      getReportShareLink,
      getShareLinkExpiration,
      moreInfoButtonMenuOptions,
      scanData,
      scan,
      shouldRenderScanDetails,
      scanStatus,
      threatEmailsTable,
      isButtonDisabled,
      sendShareTrackingEvent,
      isTimeDurationDisabled
    ]
  )
}
