import * as analyticsLib from 'global/lib/analytics/analyticsService'
import * as cloudLib from 'global/lib/cloud/cloud'
import { config } from 'global/lib/config'

import { SignupUser, SignupError } from 'global/types/api/signupUser'

export interface QueryStrings {
  'read-only'?: string
}

export interface ConnectConfig {
  provider: string
  scanType: string
  source: string
  queryStrings?: QueryStrings
  accountId?: string
  completeSignup?: (data: SignupUser & SignupError) => void
  completeSignin?: (data: any) => void
  authFailure?: (data: any) => void
  connectSuccess?: (data: any) => void
}

export interface ReconnectConfig {
  provider: string
  scanType: string
  source: string
  accountId: string
  accessTokenId: string
  connectSuccess?: (data: any) => void
  authFailure?: (data: any) => void
}

export interface ResetPasswordATOConfig {
  provider: string
  scanType: string
  source: string
  accountId: string
  accessTokenId: string
  userPrincipalName: string
  connectSuccess?: (data: any) => void
  authFailure?: (data: any) => void
}

declare global {
  interface Window {
    __completeSignup__?: (data: SignupUser & SignupError) => void
    __completeSignin__?: (data: any) => void
    __authFailure__?: (data: any) => void
    __connectSuccess__?: (data: any) => void
  }
}

function setGlobals(initConfig: ConnectConfig) {
  /* eslint-disable no-underscore-dangle */
  window.__connectSuccess__ = initConfig.connectSuccess
  window.__authFailure__ = initConfig.authFailure
  window.__completeSignup__ = initConfig.completeSignup
  window.__completeSignin__ = initConfig.completeSignin
  /* eslint-enable no-underscore-dangle */
}

export function init(initConfig: ConnectConfig): void {
  setGlobals(initConfig)

  analyticsLib.trackAppEvent(analyticsLib.EVENTS.o365Connect(initConfig.provider), {
    source: initConfig.source,
    scanType: initConfig.scanType,
    url: window.location.href
  })

  cloudLib.connect(
    initConfig.provider,
    initConfig.source,
    initConfig.scanType,
    initConfig.accountId || null,
    !!initConfig.queryStrings && initConfig.queryStrings['read-only'] === 'true'
  )
}

export function initReconnect(initConfig: ReconnectConfig): void {
  setGlobals(initConfig)

  analyticsLib.trackAppEvent(analyticsLib.EVENTS.o365Reconnect(initConfig.provider), {
    source: initConfig.source,
    scanType: initConfig.scanType,
    url: window.location.href
  })

  cloudLib.reconnect(
    initConfig.provider,
    initConfig.source,
    initConfig.scanType,
    initConfig.accountId,
    initConfig.accessTokenId
  )
}

export function initResetPasswordATO(initConfig: ResetPasswordATOConfig): void {
  setGlobals(initConfig)

  analyticsLib.trackAppEvent(analyticsLib.EVENTS.o365ResetPassword(initConfig.provider), {
    source: initConfig.source,
    scanType: initConfig.scanType,
    url: window.location.href
  })

  cloudLib.resetPasswordATO(
    initConfig.provider,
    initConfig.source,
    initConfig.scanType,
    initConfig.accountId,
    initConfig.accessTokenId,
    initConfig.userPrincipalName
  )
}

export function signupError(data: SignupUser & SignupError) {
  let error = 'default'
  switch (data.error.code) {
    case config.apiError.O365_ACCOUNT_ALREADY_EXISTS:
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.O365_ACCOUNT_ALREADY_EXISTS, { signupError: data })
      error = 'o365_account_already_connected'
      break
    case config.apiError.O365_DUPLICATE_ACCOUNT_ERROR:
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.O365_DUPLICATE_ACCOUNT_ERROR, { signupError: data })
      error = 'account_already_associated'
      break
    default:
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.O365_FAILED_TO_CONNECT, { signupError: data })
  }

  return error
}

export function authFailureError(data: any) {
  let error = 'default'
  if (data.userCancel) {
    error = 'o365_user_cancel'
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.O365_CANCEL_CONNECT_CONSENT, { authFailure: data })
  } else if (data.cancel) {
    error = 'o365_cancel_other'
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.O365_CANCEL_CONNECT_OTHER, { authFailure: data })
  } else if (data.stateTokenMismatch) {
    error = 'o365_state_token_mismatch'
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.O365_STATE_TOKEN_MISMATCH, { authFailure: data })
  } else if (data.accountAlreadyExists || data.code === 'ExistingAccountError') {
    error = 'o365_already_connected'
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.O365_ACCOUNT_ALREADY_EXISTS, { authFailure: data })
  } else if (data.duplicateAccountError || data.code === 'DuplicateAccountError') {
    error = 'account_already_exists'
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.O365_ACCOUNT_ALREADY_EXISTS, { authFailure: data })
  } else if (data.code === 'AccessTokenExpired') {
    error = 'o365_access_token_expired'
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.O365_ACCESS_TOKEN_EXPIRED, { authFailure: data })
  } else if (data.code === 'InvalidClient') {
    error = 'o365_invalid_client'
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.O365_INVALID_CLIENT, { authFailure: data })
  } else {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.O365_FAILED_TO_CONNECT, { authFailure: data })
  }

  return error
}
