import React, { useEffect, useMemo } from 'react'

import { useAppSelector } from 'global/redux/toolkit/hooks'
import GlobalStartTrial from 'global/components/features/startTrial/StartTrial'
import productLib from 'global/lib/product/product'

import routesConfig from 'fir/lib/routes/routesConfig'
import Layout from 'fir/components/lib/layout/Layout'

const BASE_I18N_KEY = 'fir.app.start_trial'

const StartTrial: React.FC = () => {
  const { accessToken } = useAppSelector((_stores: any) => ({
    accessToken: _stores.accessToken.accessToken
  }))

  useEffect(() => {
    if (productLib.hasForensicsProduct(accessToken?.id)) {
      routesConfig.REMEDIATION.goto()
    }
  }, [accessToken])

  return useMemo(
    () => (
      <Layout>
        <GlobalStartTrial BASE_I18N_KEY={BASE_I18N_KEY} />
      </Layout>
    ),
    []
  )
}

export default StartTrial
