import React, { useMemo } from 'react'
import ReactFlow from 'react-flow-renderer'

import { Button, DataTable, DataTableColumn, Paper, Typography } from '@barracuda-internal/bds-core'

import Breadcrumbs from 'global/components/lib/breadcrumbs/Breadcrumbs'
import { Cell, CellText } from 'global/components/lib/grid/cell'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import { useFormatMessage } from 'global/lib/localization'

import Layout from 'fir/components/lib/layout/Layout'
import useAutomatedWorkflowsLogic from '../useAutomatedWorkflowsLogic'
import WorkflowDialog from '../workflowDialog/WorkflowDialog'
import useWorkflowDetailLogic from './useWorkflowDetailsLogic'
import styles, { workflowDetailsGridStyles, workflowFlowDiagramStyles } from './workflowDetailsStyles'

const BASE_I18N_KEY = 'fir.app.automated_workflows.workflow_details'

type AutomatedWorkflowDetailsProps = {}

const WorkflowDetails: React.FC<AutomatedWorkflowDetailsProps> = props => {
  const classes = styles()
  const flowClasses = workflowFlowDiagramStyles()
  const gridClasses = workflowDetailsGridStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [workflowDetailsLogic] = useWorkflowDetailLogic()
  const [automatedWorkflowLogic] = useAutomatedWorkflowsLogic(props)

  return useMemo(() => {
    const {
      breadcrumbList,
      columnsConfig,
      expandConfig,
      GRID_COLUMNS,
      gridData,
      isPageLoading,
      isTableLoading,
      pageConfig,
      sortConfig,
      workflowDiagram,
      workflowDescription,
      workflowEditedBy,
      workflowUUID
    } = workflowDetailsLogic

    const { closeWorkflowDialog, openWorkflowDialog, workflowDialogConfig } = automatedWorkflowLogic

    return (
      <Layout
        pageTitle={formatMessage('labels.page_title')}
        rightContent={
          <Button
            className={classes.editWorkflowButton}
            onClick={() => openWorkflowDialog(workflowUUID)}
            color="primary"
            variant="contained"
          >
            {formatMessage('buttons.edit_workflow')}
          </Button>
        }
      >
        {isPageLoading ? (
          <LinearProgress />
        ) : (
          <>
            {workflowDialogConfig.isOpened && (
              <WorkflowDialog {...workflowDialogConfig} onClose={() => closeWorkflowDialog(workflowUUID)} />
            )}
            <div className={classes.header}>
              <div className={classes.breadcrumb}>
                <Breadcrumbs list={breadcrumbList} />
              </div>
            </div>
            <Paper className={classes.wrapper} elevation={1}>
              <div className={classes.header}>
                <div className={classes.title}>
                  <div className={classes.editor}>
                    <Typography variant="body2" className={classes.bold}>
                      {`${formatMessage('labels.editor')}:`}
                    </Typography>
                    <Typography variant="body2">{workflowEditedBy}</Typography>
                  </div>
                  <div className={classes.editor}>
                    <Typography variant="body2" className={classes.bold}>
                      {`${formatMessage('labels.description')}:`}
                    </Typography>
                    <Typography variant="body2">{workflowDescription}</Typography>
                  </div>
                </div>
              </div>
              <div className={classes.flowContainer}>
                <ReactFlow
                  className={flowClasses.flowStyles}
                  defaultPosition={[400, 0]}
                  // We need to disable allowing users to delete nodes/edges with a keypress, by default this is set to the Backspace key
                  deleteKeyCode=""
                  elements={workflowDiagram}
                  onLoad={reactFlowInstance => {
                    reactFlowInstance.fitView({ padding: 0.17 })
                  }}
                  snapGrid={[15, 15]}
                  snapToGrid
                  zoomOnDoubleClick={false}
                  zoomOnScroll={false}
                />
              </div>
            </Paper>
            <Paper className={classes.wrapper} elevation={1}>
              <div className={classes.header}>
                <div className={classes.title}>
                  <Typography variant="h5">{formatMessage('labels.workflow_run_table_title')}</Typography>
                  <Typography variant="subtitle1">{formatMessage('labels.workflow_run_table_subtitle')}</Typography>
                </div>
              </div>
              {isTableLoading && <LinearProgress />}
              <DataTable
                className={gridClasses.gridLayout}
                data={gridData}
                {...expandConfig}
                {...pageConfig}
                {...sortConfig}
              >
                <DataTableColumn
                  {...columnsConfig[GRID_COLUMNS.TIME_OF_RUN]}
                  field={GRID_COLUMNS.TIME_OF_RUN}
                  title={formatMessage('automated_workflow_runs_table.created_on')}
                  cell={({ dataItem }: { dataItem: any }) => (
                    <Cell>
                      <CellText>{dataItem.formatedDate}</CellText>
                    </Cell>
                  )}
                />
                <DataTableColumn
                  {...columnsConfig[GRID_COLUMNS.WORKFLOW_NAME]}
                  field={GRID_COLUMNS.WORKFLOW_NAME}
                  title={formatMessage('automated_workflow_runs_table.workflow_name')}
                  cell={({ dataItem }: { dataItem: any }) => (
                    <Cell>
                      <CellText>{dataItem.workflowName}</CellText>
                    </Cell>
                  )}
                />
                <DataTableColumn
                  {...columnsConfig[GRID_COLUMNS.ACTIONS_TAKEN]}
                  field={GRID_COLUMNS.ACTIONS_TAKEN}
                  title={formatMessage('automated_workflow_runs_table.actions_taken')}
                  cell={({ dataItem }: { dataItem: any }) => (
                    <Cell>
                      <CellText className={classes.capitalize}>{dataItem.actionsTaken}</CellText>
                    </Cell>
                  )}
                />
              </DataTable>
            </Paper>
          </>
        )}
      </Layout>
    )
  }, [automatedWorkflowLogic, classes, flowClasses, gridClasses, formatMessage, workflowDetailsLogic])
}

export default WorkflowDetails
