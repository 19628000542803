import { useCallback, useEffect, useMemo, useReducer } from 'react'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { IncidentDetailsSource } from 'global/types/api/newIncident'
import { getErrorMessage } from 'global/redux/toolkit/api'

import { NewIncidentDialogProps } from 'fir/components/lib/newIncidentDialog/NewIncidentDialog'

import routesConfig from 'fir/lib/routes/routesConfig'
import {
  deletePostDeliveryCard,
  deleteRelatedCard,
  dismissPostDeliveryCard,
  dismissRelatedCard,
  resetDismissCard
} from 'fir/redux/features/insights/insightsSlice'
import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'
import { PotentialThreat, ThreatSearchCriteria, ThreatSearchType } from 'fir/redux/types/Insights'

export interface UsePotentialIncidentsCardParams {
  threat: PotentialThreat
}

export interface PotentialIncidentsCardProps {
  newIncidentDialogConfig: NewIncidentDialogProps
  onCloseAlert: () => void
  onCreateIncident: (insightId: number, searchCriteria: ThreatSearchCriteria, searchType: string) => void
  onDismissCard: (insightId: number, searchType: string) => void
  onViewIncident: (incidentId: string) => void
  showDismissCardError: number
  showDemoError?: boolean
  threat: PotentialThreat
}

export default function usePotentialIncidentsCardLogic(
  params: UsePotentialIncidentsCardParams
): [PotentialIncidentsCardProps] {
  const { threat } = params
  const dispatch = useAppDispatch()
  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    insightId: null,
    newIncidentSearchCriteria: null,
    searchType: null
  })

  // Redux Toolkit stores
  const { accessTokenId, insights } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || '',
    insights: _stores.insights
  }))

  // insights state
  const { dismissCardId, dismissCardErrorMsg } = {
    dismissCardId: insights.dismissCardId,
    dismissCardErrorMsg: getErrorMessage(insights.dismissCardApiStatus)
  }

  // handle error alert
  useEffect(() => {
    dispatch(resetDismissCard())
    // eslint-disable-next-line
  }, [])

  const onCloseAlert = useCallback(() => {
    dispatch(resetDismissCard())
  }, [dispatch])

  const onCreateIncident = useCallback(
    (insightId: number, searchCriteria: ThreatSearchCriteria, searchType: string) => {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.INCIDENT_WIZARD_NEW_INCIDENT_STARTED, {
        accessTokenId,
        insightType: searchType,
        page: 'insights/potential',
        source: IncidentDetailsSource.potentialIncidents,
        insightId
      })
      setState({
        insightId,
        newIncidentSearchCriteria: {
          attachmentName: searchCriteria.attachmentName,
          bodyLinks: searchCriteria.bodyLinks,
          bodyText: searchCriteria.bodyText,
          sender: {
            address: searchCriteria.senderEmail,
            name: searchCriteria.senderName
          },
          subject: searchCriteria.subjectQuery
        },
        searchType
      })
    },
    [accessTokenId]
  )

  const onDismissCard = useCallback(
    (insightId: number, searchType: string) => {
      if (searchType === ThreatSearchType.postDelivery || searchType === ThreatSearchType.verified) {
        dispatch(dismissPostDeliveryCard({ accessTokenId, insightId }))
      } else if (searchType === ThreatSearchType.related) {
        dispatch(dismissRelatedCard({ accessTokenId, insightId }))
      }
    },
    [accessTokenId, dispatch]
  )

  const onViewIncident = useCallback((incidentId: string) => {
    routesConfig.REMEDIATION_INCIDENT.goto({ incidentId })
  }, [])

  return useMemo(
    () => [
      {
        newIncidentDialogConfig: {
          incidentDetailsSource: IncidentDetailsSource.potentialIncidents,
          isOpened: !!state.newIncidentSearchCriteria,
          ...(state.newIncidentSearchCriteria && {
            emailInfo: state.newIncidentSearchCriteria
          }),
          country: '',
          onClose: () => {
            setState({ newIncidentSearchCriteria: null })
          },
          onSuccess: () => {
            if (state.searchType === ThreatSearchType.postDelivery || state.searchType === ThreatSearchType.verified) {
              dispatch(deletePostDeliveryCard({ accessTokenId, insightId: state.insightId }))
            } else if (state.searchType === ThreatSearchType.related) {
              dispatch(deleteRelatedCard({ accessTokenId, insightId: state.insightId }))
            }
          }
        } as NewIncidentDialogProps,
        onCloseAlert,
        onCreateIncident,
        onDismissCard,
        onViewIncident,
        showDemoError: dismissCardErrorMsg === 'demo_error',
        showDismissCardError: dismissCardId,
        threat
      }
    ],
    [
      accessTokenId,
      dismissCardId,
      dismissCardErrorMsg,
      dispatch,
      onCloseAlert,
      onCreateIncident,
      onDismissCard,
      onViewIncident,
      state.insightId,
      state.newIncidentSearchCriteria,
      state.searchType,
      threat
    ]
  )
}
