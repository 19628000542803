import React, { useMemo } from 'react'

import GlobalLicenseContent from 'global/components/features/license/LicenseContent'
import { config } from 'global/lib/config'
import { useFormatMessage } from 'global/lib/localization'

import Layout from 'fir/components/lib/layout/Layout'

const BASE_I18N_KEY = 'fir.app.license'

const LicenseContent: React.FC = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <Layout pageTitle={formatMessage('page_title')}>
        <GlobalLicenseContent
          product={config.PRODUCTS.FORENSICS}
          productPrefix={config.PRODUCT_ABBR.FORENSICS}
          BASE_I18N_KEY={BASE_I18N_KEY}
        />
      </Layout>
    ),
    [formatMessage]
  )
}

export default LicenseContent
