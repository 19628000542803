import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { DateTime } from 'luxon'

import { AlertTitle, Collapse, Link } from '@barracuda-internal/bds-core'
import { Announcement } from '@barracuda-internal/bds-core/dist/Icons/Core'

import Alert from 'global/components/lib/alerts/Alert'
import { config } from 'global/lib/config'
import { useFormatMessage } from 'global/lib/localization'

const I18N_KEY = 'app.feature_banner'

const FeatureBanner: React.FC = () => {
  const formatMessage = useFormatMessage(I18N_KEY)

  const [cookies, setCookie] = useCookies(['unified-reporting-banner-dismissed'])
  const [isOpen, setIsOpen] = useState(cookies['unified-reporting-banner-dismissed'] !== 'true')

  // Set a session cookie if it doesn't exist
  useEffect(() => {
    if (!cookies['unified-reporting-banner-dismissed']) {
      setCookie('unified-reporting-banner-dismissed', false, {
        path: '/'
      })
    }
  }, [cookies, setCookie])

  const handleDismiss = useCallback(() => {
    setIsOpen(false)
    setCookie('unified-reporting-banner-dismissed', true, {
      path: '/',
      expires: DateTime.now()
        .plus({ day: 1 })
        .toJSDate()
    })
  }, [setCookie])

  const alertContent = useMemo(
    () => (
      <>
        <AlertTitle>{formatMessage('title')}</AlertTitle>
        {formatMessage('subtitle', {
          a: (txt: any) => (
            <Link key="link" target="_blank" href={config.LINKS.CAMPUS_UNIFIED_REPORTING}>
              {txt}
            </Link>
          )
        })}
      </>
    ),
    [formatMessage]
  )

  return useMemo(
    () => (
      <Collapse in={isOpen}>
        <Alert
          icon={<Announcement fontSize="inherit" />}
          pageAlert={false}
          severity="info"
          variant="standard"
          showClose
          alertContent={alertContent}
          closeAction={handleDismiss}
        />
      </Collapse>
    ),
    [alertContent, handleDismiss, isOpen]
  )
}

export default FeatureBanner
