import React, { useMemo } from 'react'

import { Grid } from '@barracuda-internal/bds-core'

import Alert, { AlertProps } from 'global/components/lib/alerts/Alert'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import styles from 'global/components/features/unifiedReporting/unifiedReportingStyles'
import DataTable, { DataTableProps } from 'global/components/lib/dataTable/DataTable'
import SideMenu, { SideMenuProps } from 'global/components/lib/sideMenu/SideMenu'
import ChartWrapper, { ChartWrapperProps } from 'global/components/lib/chartsV2/chartWrapper/ChartWrapper'

import UnifiedReportHeader, { UnifiedReportingHeaderLogicProps } from './unifiedReportingHeader/UnifiedReportingHeader'

export interface UnifiedReportingProps {
  alertConfig: AlertProps
  chartProps: ChartWrapperProps
  dataTableProps: DataTableProps
  isLoading: boolean
  sideMenuProps: SideMenuProps
  reportHeaderProps: UnifiedReportingHeaderLogicProps
}

const UnifiedReporting: React.FC<UnifiedReportingProps> = ({
  chartProps,
  dataTableProps,
  isLoading,
  alertConfig,
  sideMenuProps,
  reportHeaderProps
}) => {
  const classes = styles()

  const { chartConfig, multiSeriesDataChartConfig, singleSeriesDataChartConfig } = chartProps

  return useMemo(
    () => (
      <Grid container direction="row" className={classes.container}>
        {alertConfig.pageAlert && (
          <Grid item xs={12}>
            <Alert className={classes.linearProgress} {...alertConfig} />
          </Grid>
        )}
        {isLoading && (
          <Grid item xs={12}>
            <LinearProgress className={classes.linearProgress} />
          </Grid>
        )}
        <Grid item xs={2} className={classes.sideNav}>
          <SideMenu
            dropdownItems={sideMenuProps.dropdownItems}
            hideTitle={sideMenuProps.hideTitle}
            onSearchChange={sideMenuProps.onSearchChange}
            searchPlaceholder={sideMenuProps.searchPlaceholder}
            title={sideMenuProps.title}
          />
        </Grid>
        <Grid item xs={10} className={classes.reportPage}>
          <UnifiedReportHeader {...reportHeaderProps} />
          <Grid item xs={12}>
            <ChartWrapper
              chartConfig={chartConfig}
              multiSeriesDataChartConfig={multiSeriesDataChartConfig}
              singleSeriesDataChartConfig={singleSeriesDataChartConfig}
            />
          </Grid>
          <Grid item xs={12}>
            <DataTable {...dataTableProps} />
          </Grid>
        </Grid>
      </Grid>
    ),
    [
      alertConfig,
      chartConfig,
      classes.container,
      classes.linearProgress,
      classes.reportPage,
      classes.sideNav,
      dataTableProps,
      isLoading,
      multiSeriesDataChartConfig,
      reportHeaderProps,
      sideMenuProps.dropdownItems,
      sideMenuProps.hideTitle,
      sideMenuProps.onSearchChange,
      sideMenuProps.searchPlaceholder,
      sideMenuProps.title,
      singleSeriesDataChartConfig
    ]
  )
}

export default UnifiedReporting
