import React from 'react'

import Alert from 'global/components/lib/alerts/Alert'

export interface NavbarImpersonationBannerProps {
  shouldShowBanner: boolean
  alertContent: string
  closeAction: () => void
  pageAlert: boolean
  showClose: boolean
  closeText?: string
}

const NavbarImpersonationBanner: React.FC<NavbarImpersonationBannerProps> = ({
  shouldShowBanner,
  alertContent,
  closeAction,
  pageAlert,
  showClose,
  closeText
}) => {
  if (!shouldShowBanner) {
    return null
  }

  return (
    <Alert
      icon={false}
      pageAlert={pageAlert}
      showClose={showClose}
      alertContent={alertContent}
      closeAction={closeAction}
      closeText={closeText}
    />
  )
}

export default NavbarImpersonationBanner
