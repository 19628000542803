import { makeStyles } from '@material-ui/core/styles'

export const NAV_BAR_HEIGHT = 76 + 32 + 16 + 8 // 76px for navbar, 32px for account switcher, 16px for margin, 8px for padding
export const NAV_BAR_WIDTH = 48

export default makeStyles(() => ({
  app: {
    display: 'flex',
    flexDirection: 'row',
    height: '100vh'
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100vh',
    flex: '1 1 auto'
  },
  mainFullWidth: {
    marginLeft: NAV_BAR_WIDTH
  },
  content: {
    flex: '1 1 auto',
    overflow: 'auto'
  }
}))
