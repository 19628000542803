import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'
import { NAV_BAR_HEIGHT } from 'global/components/lib/layout/layoutStyles'

const scheduleHeaderHeight = 130

export default makeStyles(theme => ({
  linearProgress: {
    position: 'fixed',
    right: 0,
    left: 0,
    top: NAV_BAR_HEIGHT,
    backgroundColor: 'transparent'
  },
  header: {
    padding: theme.spacing(2),
    height: scheduleHeaderHeight
  },
  headerItem: {
    padding: theme.spacing(1)
  },
  searchContainer: {
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[500]}`,
    backgroundColor: theme.palette.background.paper,
    height: 32,
    width: 340,
    display: 'flex'
  },
  contentContainer: {
    height: '100%'
  },
  schedulesContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    flexWrap: 'nowrap'
  },
  menuPaper: {
    boxShadow:
      '0px 3px 14px 2px rgba(0, 0, 0, 0.12),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 5px 5px -3px rgba(0, 0, 0, 0.2)'
  },
  scheduleCard: {
    alignSelf: 'center',
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),
    width: '80%',
    boxShadow: '2px 5px 10px 3px rgba(0, 0, 0, 0.05)'
  },
  cardHeader: {
    padding: theme.spacing(1, 0.5, 1, 0.5)
  },
  cardTitle: {
    color: theme.palette.text.primary
  },
  cardTitleDisabled: {
    color: theme.palette.text.disabled
  },
  menuListItemText: {
    '& span': {
      cursor: 'pointer'
    }
  },
  cardContent: {
    padding: theme.spacing(0.5),
    minHeight: 70
  },
  chip: {
    margin: theme.spacing(0, 1, 1, 0),
    background: COLORS.GRAY_25
  },
  chipDisabled: {
    margin: theme.spacing(0, 1, 1, 0),
    background: COLORS.GRAY_25,
    color: theme.palette.text.disabled
  },
  cardActions: {
    justifyContent: 'space-between'
  },
  enabledStatusContainer: {
    display: 'flex',
    flexWrap: 'nowrap'
  },
  alert: {
    position: 'fixed',
    bottom: 0,
    margin: theme.spacing(2)
  },
  scheduleEnabledText: {
    textAlign: 'center',
    marginLeft: theme.spacing(1)
  }
}))
