import React, { useMemo, useCallback } from 'react'
import { snakeCase } from 'lodash'

import {
  NavigationBarProps,
  ProfileAvatar,
  ProfileMenu
} from '@barracuda-internal/bds-core/dist/UnifiedComponents/UnifiedNavigation'
import { Typography } from '@barracuda-internal/bds-core'
import { FaviconEmail } from '@barracuda-internal/bds-core/dist/Logos/Favicons'
import { BarChart } from '@barracuda-internal/bds-core/dist/Icons/Charts'
import { Email } from '@barracuda-internal/bds-core/dist/Icons/Email'
import {
  // TODO: Uncomment the import icon for product list when starts working on the product list logic
  // Apps,
  AutoAwesome,
  ExitToApp,
  MergeType,
  OpenInNew,
  RecentActors,
  Security,
  Settings,
  SupervisorAccount
} from '@barracuda-internal/bds-core/dist/Icons/Core'

import useAccessTokenLib from 'global/lib/accessToken/useAccessToken'
import { FEATURES, isMyFeatureOn } from 'global/lib/splitio/splitio'
import browserHistory from 'global/lib/routes/browserHistory'
import { config } from 'global/lib/config'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import useProductLib from 'global/lib/product/useProduct'
import useUserDataLib from 'global/lib/userData/useUserData'
import { useFormatMessage } from 'global/lib/localization'
import * as datetime from 'global/lib/datetime'
import { createUrlGenerator } from 'global/lib/routes/routesConfig'
import { logout } from 'global/redux/features/auth/authSlice'

import routesConfig from 'fir/lib/routes/routesConfig'
import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'

const BASE_FIR_I18N_KEY = 'fir.app.navbar'

export default function useFirNavbarLogic(): NavigationBarProps[] {
  const [accessTokenLib] = useAccessTokenLib()
  const formatMessage = useFormatMessage(BASE_FIR_I18N_KEY)
  const [productLib] = useProductLib()
  const [userDataLib] = useUserDataLib()
  const dispatch = useAppDispatch()

  const { accessTokenId, activePath, splitStore, userBccId, userEmail, userName } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || '',
    activePath: _stores.app.activePath.url || '',
    splitStore: _stores.splitio,
    userBccId: _stores.user.data.bccUserId,
    userEmail: _stores.user.data.email,
    userName: _stores.user.data.displayName || ''
  }))

  const isRouteDisabled = useMemo(() => activePath === routesConfig.START_TRIAL.path, [activePath])

  const isAdmin = useMemo(() => userDataLib.isUserAdmin(accessTokenId), [accessTokenId, userDataLib])

  // Use the current account Id to select splitio treatment value
  const accountId = useMemo(() => userDataLib.getAccountByAccessToken(accessTokenId)?.accountId, [
    accessTokenId,
    userDataLib
  ])

  const isLicenseComplianceOn = useMemo(() => isMyFeatureOn(splitStore, FEATURES.IP_LICENSING_COMPLIANCE, accountId), [
    accountId,
    splitStore
  ])

  const isLicenseVisible = useMemo(() => {
    return (
      !userDataLib.isMspManagedAccount(accessTokenId) &&
      isLicenseComplianceOn &&
      accessTokenLib.hasForensicsEntitlement(accessTokenId)
    )
  }, [accessTokenId, accessTokenLib, isLicenseComplianceOn, userDataLib])

  const getInitials = useCallback(name => {
    const names = name.split(' ')
    const initials = names.map((n: string) => n[0]).join('')
    return initials.toUpperCase()
  }, [])

  const onNavigate = useCallback((path: string) => {
    browserHistory.push(path)
  }, [])

  const generatePath = useCallback((path: string) => {
    const createUrl = createUrlGenerator(path)
    // We don't need to pass params for navbar since the function createUrlGenerator function already passed the accessTokenId param,
    // for other params like reportId, it's been handled inside the component itself.
    return `${createUrl({})}`
  }, [])

  const onGoToLicense = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.NAVBAR_REVIEW_LICENSES)
    routesConfig.LICENSE.goto({
      accessToken: accessTokenId
    })
  }, [accessTokenId])

  // start logout
  const onLogout = useCallback(() => {
    dispatch(logout(true))
  }, [dispatch])

  const serialNumber = useMemo(() => productLib.getForensicsSerialNumberForAccessToken(accessTokenId), [
    accessTokenId,
    productLib
  ])

  const expiration = useMemo(() => productLib.getForensicsSerialExpiryForAccessToken(accessTokenId), [
    accessTokenId,
    productLib
  ])

  return useMemo(
    () => [
      {
        title: '',
        currentPath: activePath,
        onNavigate,
        options: [
          // TODO: Uncomment and add the corresponding logic when starts working on the product list
          // {
          //   name: formatMessage('options.apps'),
          //   icon: <Apps />,
          //   menu: 'Custom menu content.' // This is a placeholder for the actual app menu content
          // },
          {
            name: formatMessage('options.profile'),
            icon: <ProfileAvatar size={28}>{getInitials(userName)}</ProfileAvatar>,
            menu: (
              <ProfileMenu
                icon={<ProfileAvatar>{getInitials(userName)}</ProfileAvatar>}
                name={userName}
                email={userEmail}
                menuItems={[
                  ...(isAdmin
                    ? [
                        {
                          name: formatMessage('options.profile_menu.manage_account'),
                          onClick: (): void => {
                            window.open(`${config.bccAccountUserUrl}/#user=${userBccId}`, '_blank')
                          },
                          icon: <SupervisorAccount />,
                          endIcon: <OpenInNew />
                        }
                      ]
                    : []),
                  ...(isLicenseVisible
                    ? [
                        {
                          name: formatMessage('options.profile_menu.review_licenses'),
                          onClick: onGoToLicense,
                          icon: <RecentActors />
                        }
                      ]
                    : []),

                  {
                    name: formatMessage('options.profile_menu.log_out'),
                    onClick: onLogout,
                    icon: <ExitToApp />
                  }
                ]}
                footer={
                  serialNumber || expiration ? (
                    <>
                      {serialNumber && (
                        <Typography variant="body1" color="secondary">
                          {formatMessage('serial_number', {
                            serialNumber
                          })}
                        </Typography>
                      )}
                      {expiration && (
                        <Typography variant="body2" color="secondary">
                          {formatMessage('expiration', {
                            date: datetime.formatDate(expiration, config.DATETIME.DEFAULT_DATE_FORMAT)
                          })}
                        </Typography>
                      )}
                    </>
                  ) : null
                }
              />
            )
          }
        ],
        routes: [
          {
            path: generatePath(routesConfig.REMEDIATION.path),
            icon: <Security />,
            name: formatMessage(`${snakeCase(routesConfig.REMEDIATION.id)}`),
            disabled: isRouteDisabled
          },
          {
            path: generatePath(routesConfig.USER_REPORTED.path),
            icon: <Email />,
            name: formatMessage(`${snakeCase(routesConfig.USER_REPORTED.id)}`),
            disabled: isRouteDisabled
          },
          ...(productLib.getForensicsSerialBundleForAccessToken(accessTokenId) === config.BUNDLES.BUNDLE1
            ? []
            : [
                {
                  path: generatePath(routesConfig.INSIGHTS.path),
                  icon: <AutoAwesome />,
                  name: formatMessage(`${snakeCase(routesConfig.INSIGHTS_AUTOMATED.id)}`),
                  disabled: isRouteDisabled
                },
                {
                  path: generatePath(routesConfig.AUTOMATED_WORKFLOWS.path),
                  icon: <MergeType />,
                  name: formatMessage(`${snakeCase(routesConfig.AUTOMATED_WORKFLOWS.id)}`),
                  disabled: isRouteDisabled
                },
                {
                  path: generatePath(routesConfig.UNIFIED_REPORTING_ROOT.path),
                  icon: <BarChart />,
                  name: formatMessage(`${snakeCase(routesConfig.UNIFIED_REPORTING_ROOT.id)}`),
                  disabled: isRouteDisabled,
                  children: [
                    {
                      name: formatMessage(`${snakeCase(routesConfig.REPORT_LIST.id)}`),
                      disabled: isRouteDisabled,
                      path: generatePath(routesConfig.REPORT_LIST.path)
                    },
                    {
                      name: formatMessage(`${snakeCase(routesConfig.SCHEDULED_REPORTS.id)}`),
                      disabled: isRouteDisabled,
                      path: generatePath(routesConfig.SCHEDULED_REPORTS.path)
                    }
                  ]
                }
              ]),
          {
            path: generatePath(routesConfig.SETTINGS.path),
            icon: <Settings />,
            name: formatMessage(`${snakeCase(routesConfig.SETTINGS.id)}`),
            disabled: isRouteDisabled
          }
        ],
        logo: <FaviconEmail viewBox="0 0 56 56" />
      }
    ],
    [
      accessTokenId,
      activePath,
      expiration,
      formatMessage,
      generatePath,
      getInitials,
      isAdmin,
      isLicenseVisible,
      isRouteDisabled,
      onGoToLicense,
      onLogout,
      onNavigate,
      productLib,
      serialNumber,
      userBccId,
      userEmail,
      userName
    ]
  )
}
