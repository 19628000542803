import React, { useMemo } from 'react'

import { useFormatMessage } from 'global/lib/localization'
import GlobalEmptyReport from 'global/components/features/emptyReport/EmptyReport'

import routesConfig from 'fir/lib/routes/routesConfig'
import Layout from 'fir/components/lib/layout/Layout'

export default (): React.ReactNode => {
  const formatMessage = useFormatMessage('fir.app.empty_report')

  return useMemo(
    () => (
      <Layout pageTitle={formatMessage('page_title')}>
        <GlobalEmptyReport handler={accessTokenId => routesConfig.REMEDIATION.goto({ accessToken: accessTokenId })} />
      </Layout>
    ),
    [formatMessage]
  )
}
