const EVENTS: { [key: string]: any } = {
  // account
  ACCOUNT_SIGNUP: 'Account Sign Up',

  // attack detail dialog
  OPEN_ATTACK_DETAIL: 'SP / Open SP Attack Details',
  SEARCH_FORENSICS: 'SP / Attack Preview / Search Forensics',

  // attack table dialog
  OPEN_ATTACK_TABLE: 'Open Attack Table',

  // automated workflows
  AUTOMATED_WORKFLOW_CREATE_WORKFLOW: 'Create Workflow',
  AUTOMATED_WORKFLOW_CREATE_FROM_BLUEPRINT: 'Create Workflow From Blueprint',
  AUTOMATED_WORKFLOW_EDIT_WORKFLOW: 'Edit Workflow',
  AUTOMATED_WORKFLOW_ENABLED: 'Automated Workflow Enabled',
  AUTOMATED_WORKFLOW_DISABLED: 'Automated Workflow Disabled',
  AUTOMATED_WORKFLOW_OPEN_SETTINGS: 'Automated Workflows Open Settings Dialog',
  AUTOMATED_WORKFLOW_VIEWED_ENABLED: 'Automated Workflows Viewed Enabled Workflows',
  AUTOMATED_WORKFLOW_VIEWED_DISABLED: 'Automated Workflows Viewed Disabled Workflows',

  // dashboard
  overviewDetails: (sectionName: string) => `Dash ${sectionName} Details`,
  DASHBOARD_VIEW: 'Dashboard View',
  DELETE_REPORT: 'Dash Delete Report',
  NEW_SCAN: 'Dash New Scan',
  SHARE_LINK_ENABLED: 'Dash Share Link Enabled',
  SHARE_LINK_DISABLED: 'Dash Share Link Disabled',
  SHARE_REPORT: 'Dash Share Report',
  TRIAL_ACTIVATED: 'Dash Trial Activated',
  VIEW_ATTACK_DOMAINS_DETAILS: 'Dash Attacking Domains Table View Details',
  VIEW_DMARC_DETAILS: 'Dash DMARC Table View Details',
  VIEW_EMPLOYEE_DETAILS: 'Dash Employees Table View Details',
  VIEW_SCAN_IN_PROGRESS: 'Viewed Scan in Progress',

  // account takeover
  ATO_VIEW: 'Account Takeover View',
  ATO_ALERT_OPENED_IN_EMAIL: 'Account Takeover Alert Opened in Email',
  ATO_INCIDENT_VIEW: 'Account Takeover Incident view',
  ATO_INCIDENT_WIZARD: 'Account Compromise / Alerts Table / Alert Drilldown / Add Incident Wizard',
  ATO_MARK_ALERT_AS_FP: 'Account Compromise / Alerts Table / Alert Drilldown / Marked as FP / Confirmed',
  ATO_OPEN_MARK_ALERT_AS_FP: 'Account Compromise / Alerts Table / Alert Drilldown / Mark as False Positive',
  ATO_OPEN_ALERT_DIALOG: 'Account Compromise / Alerts Table / Alert Drilldown',
  ATO_CLOSE_ALERT_DIALOG: 'Account Compromise / Alerts Table / Alert Drilldown / Close',
  ATO_SIGNINS_SET_SELECTED_RANGE: 'ATO / Sign Ins / Sign Ins by Country Map / Change Timespan',
  ATO_SIGNINS_COUNTRY_DRILLDOWN_MAP: 'ATO / Sign Ins / Sign Ins by Country Map / Country Drilldown',
  ATO_SIGNINS_COUNTRY_DRILLDOWN_TABLE: 'ATO / Sign Ins / Sign Ins by Country Table / Country Drilldown',
  ATO_SIGNINS_VIEW_USER_SIGNINS: 'ATO / Sign Ins / Investigate Account Search / View Sign Ins',
  ATO_ALERT_THREAT_GO_BACK_FROM_EMAIL_PREVIEW:
    'Account Compromise / Alerts Table / Alert Drilldown / Email Preview / Back',
  ATO_ALERT_DIALOG_VIEW_RELATED_SIGNINS: 'Account Compromise / Alerts Table / Alert Drilldown / View Related Sign Ins',
  ATO_ALERT_DIALOG_RELATED_SIGNINS_GO_BACK:
    'Account Compromise / Alerts Table / Alert Drilldown / View Related Sign Ins / Back',
  ATO_EXPORT_AS_CSV_ALERTS: 'ATO Alerts /',
  ATO_EXPORT_AS_CSV_EXTERNAL_ATTACKS: 'ATO External Attacks /',
  ATO_EXPORT_AS_CSV_INTERNAL_ATTACKS: 'ATO Internal Attacks /',

  // cta
  CTA_BARRACUDA_OUTLOOK_ADDIN: 'CTA / Barracuda Outlook Addin Link',

  // domain_fraud
  DF_DOMAINS_ADD_DOMAIN: 'Domain Fraud / Domains Table / Add Domain',
  DF_DOMAINS_VERIFY_DOMAIN: 'Domain Fraud / Domains Table / Verify Domain',
  DF_DOMAINS_FRAUD_PROTECTION_WIZARD: 'Domain Fraud / Domains Table / Fraud Protection Wizard',
  DF_DOMAINS_OPEN_ENFORCE_DMARC_WIZARD: 'Domain Fraud / Domains Table / Enforce DMARC Wizard',
  DF_DOMAINS_CLOSE_READY_TO_ENFORCE: 'Domain Fraud / Domains Table / Close Ready to Enforce Message',
  DF_DOMAINS_VIEW_REPORT_UNKNOWN: 'Domain Fraud / Domains Table / View Report Unknown)',
  DF_DOMAINS_VIEW_APPROVED_TAB: 'Domain Fraud / Domains Table / View Report (Approved)',
  DF_DOMAINS_DELETE_DOMAIN: 'Domain Fraud / Domains Table / Delete Domain',
  DF_DOMAINS_DELETE_DOMAIN_CONFIRMED: 'Domain Fraud / Domains Table / Delete Domain / Confirmed',
  DF_DOMAINS_DELETE_DOMAIN_CANCEL: 'Domain Fraud / Domains Table / Delete Domain / Cancel',
  DF_DOMAINS_WIZARD_CHECK_SPF: 'Domain Fraud / Fraud Protection Wizard / Check SPF',
  DF_DOMAINS_WIZARD_PREPARE_DMARC_RECORD: 'Domain Fraud / Fraud Protection Wizard / Prepare DMARC Record',
  DF_DOMAINS_WIZARD_CHECK_DMARC: 'Domain Fraud / Fraud Protection Wizard / Check DMARC',
  DF_DOMAINS_DMARC_REVIEW_SELECT_TAB: 'Domain Fraud / DMARC Review / Select Tab',
  DF_DOMAINS_DMARC_REVIEW_BACK_TO_DOMAINS: 'Domain Fraud / DMARC Review / Back to Domains',
  DF_DOMAINS_DMARC_REVIEW_UNKNOWN_TAB_PAGINATE: 'Domain Fraud / DMARC Review / Unknown Tab / Paginate',
  DF_DOMAINS_DMARC_REVIEW_UNKNOWN_TAB_VISIT_SOURCE: 'Domain Fraud / DMARC Review / Unknown Tab / Visit Source',
  DF_DOMAINS_DMARC_REVIEW_UNKNOWN_TAB_OPEN_INVESTIGATE_DIALOG:
    'Domain Fraud / DMARC Review / Unknown Tab / Investigate',
  DF_DOMAINS_DMARC_REVIEW_UNKNOWN_TAB_MARK_AS_REJECTED: 'Domain Fraud / DMARC Review / Unknown Tab / Mark not Approved',
  DF_DOMAINS_DMARC_REVIEW_UNKNOWN_TAB_MARK_AS_APPROVED: 'Domain Fraud / DMARC Review / Unknown Tab / Mark Approved',
  DF_DOMAINS_DMARC_REVIEW_UNKNOWN_TAB_UNDO: 'Domain Fraud / DMARC Review / Unknown Tab / Undo',
  DF_DOMAINS_DMARC_REVIEW_UNKNOWN_TAB_SEARCH: 'Domain Fraud / DMARC Review / Unknown Tab / Search',
  DF_DOMAINS_DMARC_REVIEW_UNKNOWN_TAB_MARK_ALL_AS_REJECTED:
    'Domain Fraud / DMARC Review / Unknown Tab / Mark All Low Volume Sources as Rejected',
  DF_DOMAINS_DMARC_REVIEW_APPROVED_TAB_PAGINATE: 'Domain Fraud / DMARC Review / Approved Tab / Paginate',
  DF_DOMAINS_DMARC_REVIEW_APPROVED_TAB_OPEN_INVESTIGATE_DIALOG:
    'Domain Fraud / DMARC Review / Approved Tab / Investigate',
  DF_DOMAINS_DMARC_REVIEW_APPROVED_TAB_MARK_AS_REJECTED:
    'Domain Fraud / DMARC Review / Approved Tab / Mark not Approved',
  DF_DOMAINS_DMARC_REVIEW_APPROVED_TAB_SEARCH: 'Domain Fraud / DMARC Review / Approved Tab / Search',
  DF_DOMAINS_DMARC_REVIEW_REJECTED_TAB_PAGINATE: 'Domain Fraud / DMARC Review / Not Approved Tab / Paginate',
  DF_DOMAINS_DMARC_REVIEW_REJECTED_TAB_MARK_AS_APPROVED:
    'Domain Fraud / DMARC Review / Not Approved Tab / Mark as Approved',
  DF_DOMAINS_DMARC_REVIEW_REJECTED_TAB_SEARCH: 'Domain Fraud / DMARC Review / Not Approved Tab / Search',
  DF_DOMAINS_DMARC_REVIEW_INVESTIGATE_DIALOG_CHANGE_DIALOG_MODE: 'Domain Fraud / Investigate / Change Dialog Mode',
  DF_DOMAINS_DMARC_REVIEW_INVESTIGATE_DIALOG_CHANGE_BACK: 'Domain Fraud / Investigate / Back',
  DF_DOMAINS_DMARC_REVIEW_INVESTIGATE_DIALOG_CLOSE: 'Domain Fraud / Investigate / Close Dialog',
  DF_DOMAINS_DMARC_REVIEW_INVESTIGATE_DIALOG_OPEN_DKIM_RECOMMENDATION:
    'Domain Fraud / Investigate / Open DKIM Recommendation',
  DF_DOMAINS_DMARC_REVIEW_INVESTIGATE_DIALOG_OPEN_SPF_RECOMMENDATION:
    'Domain Fraud / Investigate / Open SPF Recommendation',
  DF_DOMAINS_DMARC_REVIEW_INVESTIGATE_DIALOG_OPEN_ALIGNMEN_RECOMMENDATION:
    'Domain Fraud / Investigate / Open Alignment Recommendation',
  DF_DOMAINS_DMARC_REVIEW_COUNTRIES_OPEN_DIALOG: 'Domain Fraud / DMARC Review / Countries / Open Countries Dialog',
  DF_DOMAINS_DMARC_REVIEW_COUNTRIES_CLOSE_DIALOG: 'Domain Fraud / DMARC Review / Countries / Close Dialog',
  DF_DOMAINS_DMARC_REVIEW_COUNTRIES_CLICK_COUNTRY:
    'Domain Fraud / DMARC Review / Countries / Click Country to Show Ruf Samples',
  DF_DOMAINS_DMARC_REVIEW_COUNTRIES_PREVIEW_SAMPLES:
    'Domain Fraud / DMARC Review / Countries / Ruf Samples Table / Preview',
  DF_DOMAINS_DMARC_REVIEW_COUNTRIES_BACK_TO_ALL_COUNTRIES:
    'Domain Fraud / DMARC Review / Countries / Back to all Countries',
  DF_DOMAINS_DMARC_REVIEW_COUNTRIES_RUF_SAMPLES_TABLE_PREVIEW:
    'Domain Fraud / DMARC Review / Countries / Ruf Samples Table / Preview',
  DF_DOMAINS_DMARC_REVIEW_COUNTRIES_RUF_SAMPLES_TABLE_PAGINATE:
    'Domain Fraud / DMARC Review / Countries / Ruf Samples Table / Paginate',
  DF_DOMAINS_DMARC_REVIEW_COUNTRIES_RUF_SAMPLES_PREVIEW_DOWNLOAD_EMAIL:
    'Domain Fraud / DMARC Review / Ruf Samples / Preview / Download Email',

  // domain fraud - DMARC enforcement
  DMARC_VIEW: 'Domain Fraud View',
  DF_DMARC_ENFORCEMENT_CLOSE: 'Domain Fraud / DMARC Enforcement / Close',
  DF_DMARC_ENFORCEMENT_BACK_TO_INIT: 'Domain Fraud / DMARC Enforcement / Back to Init',
  DF_DMARC_ENFORCEMENT_CHECK: 'Domain Fraud / DMARC Enforcement / Check DMARC',
  DF_DMARC_REVIEW_DNS_CONFIG_CHANGE_TO_REPORTING_ONLY:
    'Domain Fraud / DMARC Review / DNS Config / Change to DMARC Reporting Only',
  DF_DMARC_REVIEW_DNS_CONFIG_ENFORCE_DMARC: 'Domain Fraud / DMARC Review / DNS Config / Enforce DMARC',

  // free trial page
  FREE_TRIAL_START_BOOTSTRAP: 'Start Bootstrap',
  FREE_TRIAL_STARTED: 'Free Trial Started',

  // Sentinel start trial page
  START_TRIAL_PAGEVIEW: 'Start Trial Pageview',

  // incident wizard
  incidentWizardBack: (step: string) => `Incident Wizard / ${step} / Back`,
  incidentWizardCancel: (step: string) => `Incident Wizard / ${step} / Cancel`,
  incidentWizardNext: (step: string) => `Incident Wizard / ${step} / Next`,
  INCIDENT_WIZARD_APPLY_PREVIEW: 'Incident Wizard / User Options / Apply preview notification',
  INCIDENT_WIZARD_CANCEL_PREVIEW: 'Incident Wizard / User Options / Cancel preview notification',
  INCIDENT_WIZARD_NEW_INCIDENT_STARTED: 'New Incident Started',
  INCIDENT_WIZARD_PREVIEW_NOTIFICATION: 'Incident Wizard / User Options / Preview notification',
  INCIDENT_WIZARD_REVIEW_MESSAGES: 'Incident Wizard / Review Messages / Review Email',

  // global export as CSV
  exportAsCsv: (exportType: string) => `${exportType} Export as CSV`,

  // incident details page
  CONTINUOUS_REMEDIATION_STATUS: 'Incident Details Continuous Remediation Status',
  DELETE_EXISTING_EMAILS: 'Incident Details Delete Existing Emails',
  VIEW_INCIDENT: 'Viewed Incident',

  // incidents page
  VIEW_INCIDENTS_PAGE: 'Viewed Incidents Page',

  // insights
  INSIGHTS_VIEW_GEOIP_PAGE: 'Viewed GeoIP',
  INSIGHTS_REVIEW_COUNTRY: 'Review Country GeoIP',
  VIEW_INSIGHTS_PAGE: 'Viewed Insights page',

  // Keyword highlighting
  TOGGLE_HIGHLIGHTING: 'Toggle highlighting',

  // mylink
  MY_LINK_PAGEVIEW: 'Mylink Pageview',
  MY_LINK_COPY: 'Mylink Copy Text',

  // navbar
  NAVBAR_CLOUD_CONNECT: 'Navbar Cloud Connect',
  NAVBAR_CONNECT_O365_SUCCESSFUL: 'Navbar Connect Office365 Successful',
  NAVBAR_LOGOUT: 'Navbar Click Logout',
  NAVBAR_CLICK_NOTICEABLE: 'Navbar Click Noticeable',
  NAVBAR_REVERT_IMPERSONATION: 'Navbar Revert Impersonation',
  NAVBAR_SET_SERIAL: 'Navbar Set Serial Number',
  NAVBAR_SWITCH_ACCOUNT: 'Navbar Switch Account',
  NAVBAR_REVIEW_LICENSES: 'Navbar Review Licenses',

  // office365 connect
  O365_ACCESS_TOKEN_EXPIRED: 'Office 365 Access Token Expired',
  O365_INVALID_CLIENT: 'Office 365 Invalid Client',
  O365_ACCOUNT_ALREADY_EXISTS: 'Office Account Already Exists',
  O365_CANCEL_CONNECT_CONSENT: 'Office365 Cancelled Connect: User declined to consent',
  O365_CANCEL_CONNECT_OTHER: 'Office365 Cancelled Connect: Other',
  O365_DUPLICATE_ACCOUNT_ERROR: 'Office365 Duplicate Account Exception',
  o365Connect: (provider: string) => `Connect ${provider}`,
  o365Reconnect: (provider: string) => `Reconnect ${provider}`,
  o365ResetPassword: (provider: string) => `Reset password ATO ${provider}`,
  O365_EMPTY_REPORT_CONNECT_SUCCESSFUL: 'Empty Report Connect Office365 Successful',
  O365_FAILED_TO_CONNECT: 'Office365 Failed To Connect',
  O365_STATE_TOKEN_MISMATCH: 'Office365 State Token Mismatch',
  SIGNUP_CONNECT_O365_SUCCESSFUL: 'Signup Connect Office365 Successful',
  SIGNIN_CONNECT_O365_SUCCESSFUL: 'Signin Connect Office365 Successful',
  RECONNECT_O365_SUCCESSFUL: 'Reconnect Office365 Successful',

  // Permissions
  ATO_TAB_ADD_PERMISSIONS: 'ATO Tab Add Permissions',
  FIR_ADD_PERMISSIONS: 'Add Permissions',
  INBOX_RULES_ADD_PERMISSIONS: 'ATO Inbox Rules Tab Add Permissions',
  ATO_WIZARD_ADD_PERMISSIONS: 'ATO New Incident / Inbox Rules / Add Permissions',

  // pdf
  PDF_REPORT_REQUEST: 'Dash PDF Report',

  // Phishline
  CREATE_SECURITY_TRAINING_ADDRESS_BOOK: 'Create Security Training Address Book',

  // report
  REPORT_OPENED_IN_EMAIL: 'Report Opened in Email',
  REPORT_OPENED_IN_PDF: 'Report Opened in PDF',

  // settings dialog
  updateSettings: (setting: string) => `Update ${setting} setting`,
  OPEN_SETTINGS: 'Open Settings Dialog',
  SAVE_SETTINGS: 'Saved Settings',

  // signin
  SIGNIN_PAGEVIEW: 'Signin Pageview',
  SIGNIN_SIGNUP: 'Signup Signin',
  SIGNUP_SIGNIN_SUCCESSFUL: 'Signup Signin Successful',
  SIGNIN_LOGIN: 'Signin Click Login',
  SIGNIN_CONNECT_PAGEVIEW: 'Signin Connect Pageview',
  SIGNIN_CONNECT_O365: 'Signin Connect Click O365',

  // signup
  SIGNUP_DOMAIND_FRAUD: 'Domain Fraud Signup Pageview',
  SIGNUP_DOMAIND_FRAUD_PROFILE: 'Domain Fraud Signup Profile Pageview',
  SIGNUP_DPL_CHECK_FAIL: 'Signup DPL Check Failure',
  SIGNUP_FORGOT_PASSWORD: 'Signup Forgot Password',
  SIGNUP_PAGEVIEW: 'Signup Pageview',
  SIGNUP_PERMISSION: 'Signup Permissions',
  SIGNUP_PERMISSION_ACCORDION: 'Signup Permission Accordion',
  SIGNUP_REQUEST_DEMO: 'Signup Request Demo',
  SIGNUP_SAVE_PROFILE_PAGEVIEW: 'Signup Save Profile Pageview',
  SIGNUP_SAVE_PROFILE: 'Signup Save Profile',
  SIGNUP_WATCH_DEMO_VIDEO: 'Signup Watch Demo Video - Start',
  SIGNUP_WATCH_DEMO_VIDEO_END: 'Signup Watch Demo Video - End',
  SIGNUP_SPEAK_TO_EXPERT: 'Signup Speak to an Expert',

  // threats table
  VIEW_THREATS_DETAILS: 'Dash Threats Table View Details',

  // user-reported page
  USER_REPORTED_DISMISS_MESSAGE: 'Dismiss User Reported Message',
  USER_REPORTED_VIEW_SUBMITTED: 'View Submitted Messages',
  USER_REPORTED_VIEWED: 'Viewed User Reported page',
  USER_REPORTED_VIEWED_DISMISSED: 'View Dismissed Messages',

  // report false positive
  OPEN_REPORT_FALSE_POSITIVE: 'SP / Report False Positive for SP Attack',
  REPORT_FALSE_POSITIVE: 'SP / Report False Positive for SP Attack / FPFN',
  REPORT_MISSED_ATTACK: 'SP / Open False Negatives Dialog',

  // Test AI
  TEST_AI_OPEN: 'SP / Open Test Attack Dialog',
  TEST_AI_TEMPLATE: 'Test AI / Select SP Template Dropdown / Change Template',
  TEST_AI_FILTER_ATTACKS: 'Test AI / Filter Attacks',
  TEST_AI_FILTER_ATTACKS_CHECKBOX: 'Test AI / Filter Attacks / Toggle Checkbox',
  TEST_AI_SEND: 'SP / Send Test Attack Dialog / Send Attack',

  // Settings dialogs
  SP_OPEN_SETTINGS: 'SP / Open Settings Dialog',

  // Dashboard cards
  DASH_PHISHING_CARD: 'Dashboard Cards / Real-time Phishing / View ',
  DASH_ATO_CARD: 'Dashboard Cards / ATO / View Alerts metadata',
  DASH_DMARC_CARD: 'Dashboard Cards / DMARC / View Reporting',
  DASH_MAILBOX_CARD: 'Dashboard Cards / View Mailboxes metadata',

  // Cleanup pages
  CLEANUP_VIEW: 'Cleanup Pageview',
  CLEANUP_DIALOG_OPEN: 'Cleanup / Confirm Removing Attacks / Open',
  CLEANUP_DIALOG_CANCEL: 'Cleanup / Confirm Removing Attacks / Cancel',
  CLEANUP_DIALOG_CONFIRM: 'Cleanup / Confirm Removing Attacks / Confirm',

  // SEN New Create Incident
  ACCOUNT_COMPROMISE_NEW_INCIDENT: 'Account Compromise New Incident',
  ACCOUNT_COMPROMISE_INCIDENT_CLOSE: 'Account Compromise New Incident Close',

  // SEN New Incident Wizard
  ACCOUNT_COMPROMISE_INCIDENT_WIZARD_CLEAN_UP: 'Account Compromise Incident Wizard Clean Up Messages',

  // SEN New Incident Wizard: Create Incident
  ACCOUNT_COMPROMISE_INCIDENT_WIZARD_EMAIL_PREVIEW: 'Account Compromise Incident Wizard Email Preview',
  ACCOUNT_COMPROMISE_INCIDENT_WIZARD_SUBJECT_SEARCH: 'Account Compromise Incident Wizard Subject Search',
  ACCOUNT_COMPROMISE_INCIDENT_WIZARD_CREATE_INCIDENT: 'Account Compromise Incident Wizard Create Incident',

  // SEN New Incident Wizard: External Recipients Notification
  ACCOUNT_COMPROMISE_INCIDENT_WIZARD_EDIT_NOTIFY_EMAIL: 'Account Compromise Incident Wizard Edit Notify Email',
  ACCOUNT_COMPROMISE_WIZARD_NOTIFY_EXTERNAL: 'Account Compromise Incident Wizard Notify External Recipients',

  // SEN New Incident Wizard: General events
  ACCOUNT_COMPROMISE_INCIDENT_WIZARD_NEXT: 'Account Compromise Incident Wizard Next',
  ACCOUNT_COMPROMISE_INCIDENT_WIZARD_BACK: 'Account Compromise Wizard Back',
  ACCOUNT_COMPROMISE_INCIDENT_WIZARD_CLOSE: 'Account Compromise Incident Wizard Close',

  // SEN Unregistered page
  UNREGISTERED_PAGE_VISITED: 'Unregistered page visited',

  // SEN License Compliance Page
  LC_PAGEVIEW: 'License Compliance Pageview',

  // SEN FPFN Feedback Form
  SEN_FPFN_FEEDBACK_FORM_PAGEVIEW: 'FPFN Feedback Form Pageview',
  SEN_FPFN_FEEDBACK_FORM_BUTTON_CLICK: 'FPFN Feedback Form Button Click',

  // Unified reporting page
  UNIFIED_REPORTING_EDIT_REPORT: 'Unified Reporting edit report',
  UNIFIED_REPORTING_EXPORT_REPORT: 'Unified Reporting export report',
  UNIFIED_REPORTING_REPORT_VIEW: 'Unified Reporting page view',
  UNIFIED_REPORTING_SAVE_REPORT: 'Unified Reporting save report',

  // Unified reporting scheduling
  SCHEUDLED_REPORT_CREATE: 'Unified Reporting create schedule',
  SCHEDULED_REPORT_DELETE: 'Unified Reporting delete schedule',
  SCHEUDLED_REPORT_DISABLED: 'Unified Reporting disable schedule',
  SCHEDULED_REPORT_ENABLED: 'Unified Reporting enable schedule',
  SCHEDULED_REPORT_OPEN_SCHEDULE_MODAL: 'Unified Reporting open schedule modal',
  SCHEDULED_REPORTS_PAGEVIEW: 'Unified Reporting schedule page view',
  SCHEDULED_REPORT_UPDATE: 'Unified Reporting updated schedule'
}

export default EVENTS
