import React, { useCallback, useEffect, useMemo } from 'react'
import { DateTime, Info } from 'luxon'

import { ChartPeriod } from 'global/components/lib/chartsV2/ChartConfig'
import { useFormatMessage } from 'global/lib/localization'
import { formatNumber } from 'global/lib/number'
import { resetScheduledReport } from 'global/redux/features/scheduledReports/scheduledReportsSlice'
import { useAppDispatch } from 'global/redux/toolkit/hooks'
import { RelativeDateRange } from 'global/types/api/unifiedReporting' // TODO: move to shared reporting types
import { ScheduledReport } from 'global/types/api/scheduledReports'

const BASE_I18N_KEY = 'app.scheduled_reports'

type UseScheduledReportsLogic = {
  anchorEl: null | HTMLElement
  formatRelativeDateRange: (relativeDateRange: string) => string
  handleScheduleCardMenuClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  handleScheduleCardMenuClose: () => void
  isScheduledReportsDrawerOpen: boolean
  open: boolean
  scheduleFrequency: (schedule: ScheduledReport) => string
  targetedSchedule: ScheduledReport | undefined
}

const useScheduledReportsLogic = ({
  isScheduledReportsDrawerOpen,
  schedules
}: {
  isScheduledReportsDrawerOpen: boolean
  schedules: ScheduledReport[]
}): UseScheduledReportsLogic[] => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const dispatch = useAppDispatch()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const targetedSchedule = useMemo(() => schedules.find(schedule => schedule.uuid === anchorEl?.id), [
    anchorEl,
    schedules
  ])
  const open = Boolean(anchorEl)

  useEffect(() => {
    if (!isScheduledReportsDrawerOpen) {
      dispatch(resetScheduledReport())
    }
  }, [dispatch, isScheduledReportsDrawerOpen])

  const formatRelativeDateRange = useCallback(
    (relativeDateRange: string) => {
      switch (relativeDateRange) {
        case RelativeDateRange.LAST_DAY:
          return formatMessage('relative_date_range.last_day')
        case RelativeDateRange.LAST_3_DAYS:
          return formatMessage('relative_date_range.last_x_days', { days: 3 })
        case RelativeDateRange.LAST_7_DAYS:
          return formatMessage('relative_date_range.last_x_days', { days: 7 })
        case RelativeDateRange.LAST_30_DAYS:
          return formatMessage('relative_date_range.last_x_days', { days: 30 })
        case RelativeDateRange.LAST_90_DAYS:
          return formatMessage('relative_date_range.last_x_days', { days: 90 })
        case RelativeDateRange.LAST_6_MONTHS:
          return formatMessage('relative_date_range.last_6_months')
        default:
          return ''
      }
    },
    [formatMessage]
  )

  const scheduleFrequency = useCallback(
    (schedule: ScheduledReport) => {
      const scheduledTime = DateTime.fromFormat(schedule.frequencyTime, 'HH:mm', {
        zone: schedule.zoneId
      }).toFormat('h:mm a ZZZZ')
      // Luxon starts week on Monday, but we want to display it starting on Sunday
      const adjustedDayOfWeek = schedule.frequencyDayOfWeek === 0 ? 6 : schedule.frequencyDayOfWeek - 1
      const scheduledDayOfWeek = Info.weekdays()[adjustedDayOfWeek]

      switch (schedule.frequency) {
        case ChartPeriod.daily:
          return formatMessage('schedule_date_time.daily', { scheduledTime })
        case ChartPeriod.weekly:
          return formatMessage('schedule_date_time.weekly', { scheduledDayOfWeek, scheduledTime })
        case ChartPeriod.monthly:
          return schedule.frequencyMonthly === 'SPECIFIC_DATE'
            ? formatMessage('schedule_date_time.specific_date', {
                frequencyDayOfMonth:
                  schedule.frequencyDayOfMonth > 0
                    ? formatNumber(schedule.frequencyDayOfMonth, '0o')
                    : formatMessage('schedule_date_time.last_day_of_month'),
                scheduledTime
              })
            : formatMessage('schedule_date_time.monthly', {
                frequencyMonthly: schedule.frequencyMonthly.toLocaleLowerCase(),
                scheduledDayOfWeek,
                scheduledTime
              })
        default:
          return ''
      }
    },
    [formatMessage]
  )

  const handleScheduleCardMenuClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleScheduleCardMenuClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return useMemo(
    () => [
      {
        anchorEl,
        formatRelativeDateRange,
        handleScheduleCardMenuClick,
        handleScheduleCardMenuClose,
        isScheduledReportsDrawerOpen,
        open,
        scheduleFrequency,
        targetedSchedule
      }
    ],
    [
      anchorEl,
      formatRelativeDateRange,
      handleScheduleCardMenuClick,
      handleScheduleCardMenuClose,
      isScheduledReportsDrawerOpen,
      open,
      scheduleFrequency,
      targetedSchedule
    ]
  )
}

export default useScheduledReportsLogic
