import React from 'react'

import GlobalLayout, { GlobalLayoutProps } from 'global/components/lib/layout/Layout'

import FirNavbar from './Navbar'
import useLayoutLogic from './useLayoutLogic'

const Layout = (props: GlobalLayoutProps): JSX.Element => {
  const [layoutLogic] = useLayoutLogic()
  const { navbarContextMenuO365Props, onSelectAccountCallback } = layoutLogic

  return (
    <GlobalLayout
      {...props}
      isNewLayout
      isPermissionBannerEnabled
      isMaintenanceBannerEnabled
      navbar={<FirNavbar />}
      navbarContextMenuO365Props={navbarContextMenuO365Props}
      onSelectAccountCallback={onSelectAccountCallback}
    />
  )
}

export default Layout
