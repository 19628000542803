/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react'

import {
  Button,
  Card,
  Link,
  Typography,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Tooltip,
  Alert,
  Box
} from '@barracuda-internal/bds-core'
import { Help as HelpIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import { config } from 'global/lib/config'
import * as datetime from 'global/lib/datetime'
import { useFormatMessage } from 'global/lib/localization'

import NewIncidentDialog from 'fir/components/lib/newIncidentDialog/NewIncidentDialog'
import { ThreatSearchType } from 'fir/redux/types/Insights'

import usePotentialIncidentsCardLogic, { UsePotentialIncidentsCardParams } from './usePotentialIncidentsCardLogic'
import styles from './potentialIncidentsCardStyles'

const BASE_I18N_KEY = 'fir.app.potential_incidents.cards'

const PotentialIncidentCard: React.FC<UsePotentialIncidentsCardParams> = props => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [potentialIncidentsCardLogic] = usePotentialIncidentsCardLogic(props)

  return useMemo(() => {
    const {
      newIncidentDialogConfig,
      onCloseAlert,
      onCreateIncident,
      onDismissCard,
      onViewIncident,
      showDemoError,
      showDismissCardError,
      threat
    } = potentialIncidentsCardLogic

    const header = (
      <Typography variant="h5" className={classes.header}>
        <span>
          {formatMessage(
            `${
              threat.searchType === ThreatSearchType.postDelivery || threat.searchType === ThreatSearchType.verified
                ? 'community_title'
                : 'similar_title'
            }`
          )}
        </span>
        <Tooltip
          title={formatMessage(
            `${
              threat.searchType === ThreatSearchType.postDelivery || threat.searchType === ThreatSearchType.verified
                ? 'community_tooltip'
                : 'similar_tooltip'
            }`
          )}
          className={classes.tooltip}
        >
          <Box>
            <HelpIcon />
          </Box>
        </Tooltip>
      </Typography>
    )

    return (
      <>
        {newIncidentDialogConfig.isOpened && <NewIncidentDialog {...newIncidentDialogConfig} />}
        <Grid item xs={4} className={classes.root}>
          <Card className={classes.card}>
            <CardHeader title={header} className={classes.header} />
            {showDemoError && showDismissCardError === threat.id && (
              <Alert variant="filled" severity="error" onClose={onCloseAlert}>
                {formatMessage('demo_error')}
              </Alert>
            )}
            {!showDemoError && showDismissCardError === threat.id && (
              <Alert variant="filled" severity="error" onClose={onCloseAlert}>
                {formatMessage('dismiss_error')}
              </Alert>
            )}
            <CardContent>
              <div className={classes.matchedEmailCount}>{threat.matchedEmailCount}</div>
              <Typography variant="subtitle1" color="textSecondary">
                {`${formatMessage('emails_detected')} `}
                {datetime.formatDate(threat.reportedDate, config.DATETIME.DEFAULT_DATE_WITH_TIME_FORMAT)}
              </Typography>
              <Typography variant="h6" color="textSecondary" className={classes.threatDetails}>
                {formatMessage('threat_details')}:
              </Typography>
              {threat.searchCriteria.senderEmail && (
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Typography variant="body1">{formatMessage('sender_email')}:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1" className={classes.bold}>
                      {threat.searchCriteria.senderEmail}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {threat.searchCriteria.senderName && (
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Typography variant="body1">{formatMessage('sender_name')}:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1" className={classes.bold}>
                      {threat.searchCriteria.senderName}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {threat.searchCriteria.subjectQuery && (
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Typography variant="body1">{formatMessage('subject')}:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1" className={classes.bold}>
                      {threat.searchCriteria.subjectQuery}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {threat.searchCriteria.bodyText && (
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Typography variant="body1">{formatMessage('body_text')}:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1" className={classes.bold}>
                      {threat.searchCriteria.bodyText}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {threat.searchCriteria.bodyLinks && (
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Typography variant="body1">{formatMessage('body_links')}:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1" className={classes.bold}>
                      {threat.searchCriteria.bodyLinks}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {threat.searchCriteria.attachmentName && (
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Typography variant="body1">{formatMessage('attachment')}:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1" className={classes.bold}>
                      {threat.searchCriteria.attachmentName}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {threat.incidentId && (
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Typography variant="body1">{formatMessage('incident')}:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1">
                      <Link
                        href=""
                        onClick={() => {
                          onViewIncident(threat.incidentId as string)
                        }}
                      >
                        {formatMessage('view_incident')}
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </CardContent>
            <CardActions className={classes.actions}>
              <Button
                color="primary"
                variant="text"
                onClick={() => {
                  onDismissCard(threat.id, threat.searchType)
                }}
              >
                {formatMessage('dismiss')}
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  onCreateIncident(threat.id, threat.searchCriteria, threat.searchType)
                }}
              >
                {formatMessage('create_incident')}
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </>
    )
  }, [classes, formatMessage, potentialIncidentsCardLogic])
}

export default PotentialIncidentCard
