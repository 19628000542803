import React, { useMemo } from 'react'
import { Button } from '@barracuda-internal/bds-core'

import GlobalScheduledReports from 'global/components/features/scheduledReports/ScheduledReports'

import Layout from 'fir/components/lib/layout/Layout'

import useScheduledReportsLogic from './useScheduledReportsLogic'

const ScheduledReports: React.FC = () => {
  const [scheduledReportsLogic] = useScheduledReportsLogic()
  const {
    handleOpenScheduledReport,
    isLoading,
    isScheduledReportsDrawerOpen,
    newScheduledReportButtonLabel,
    onClickEnabledState,
    onClickDelete,
    onClickOpenReport,
    onSearchChange,
    pageTitle,
    scheduledReportsDrawerConfig,
    schedules,
    searchValue,
    toggleDrawer
  } = scheduledReportsLogic

  return useMemo(
    () => (
      <Layout
        pageTitle={pageTitle}
        rightContent={
          <Button
            color="primary"
            size="large"
            variant="contained"
            onClick={e => {
              handleOpenScheduledReport()
              toggleDrawer(e)
            }}
          >
            {newScheduledReportButtonLabel}
          </Button>
        }
      >
        <GlobalScheduledReports
          handleOpenScheduledReport={handleOpenScheduledReport}
          hideTitle
          isLoading={isLoading}
          isScheduledReportsDrawerOpen={isScheduledReportsDrawerOpen}
          onClickEnabledState={onClickEnabledState}
          onClickDelete={onClickDelete}
          onClickOpenReport={onClickOpenReport}
          scheduledReportsDrawerConfig={scheduledReportsDrawerConfig}
          schedules={schedules}
          onSearchChange={onSearchChange}
          searchValue={searchValue}
          toggleDrawer={toggleDrawer}
        />
      </Layout>
    ),
    [
      handleOpenScheduledReport,
      isLoading,
      isScheduledReportsDrawerOpen,
      newScheduledReportButtonLabel,
      onClickDelete,
      onClickEnabledState,
      onClickOpenReport,
      onSearchChange,
      pageTitle,
      scheduledReportsDrawerConfig,
      schedules,
      searchValue,
      toggleDrawer
    ]
  )
}

export default ScheduledReports
