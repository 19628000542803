import { useCallback, useMemo } from 'react'
// TODO: Import Account type directly when BDS exports it
import { Account as BDSAccountSwitcherAccount } from '@barracuda-internal/bds-core/dist/UnifiedComponents/AccountSwitcher/AccountSwitcher'

import { setCurrentAccessToken, setCurrentBccAccount } from 'global/redux/features/accessToken/accessTokenSlice'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import useUserDataLib from 'global/lib/userData/useUserData'
import { NavbarContextMenuO365Props } from 'global/components/lib/layout/navbar'

import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'
import { forensicsGetEssAccount } from 'fir/redux/features/remediation/remediationSlice'
import routesConfig from 'fir/lib/routes/routesConfig'

export interface FirLayoutLogic {
  navbarContextMenuO365Props?: NavbarContextMenuO365Props
  onSelectAccountCallback: (selectedAccount: BDSAccountSwitcherAccount) => void
}

export default function useLayoutLogic(): FirLayoutLogic[] {
  const dispatch = useAppDispatch()
  const [userDataLib] = useUserDataLib()

  const { accessTokenId } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id
  }))

  const accounts = userDataLib.getAccounts()

  const onSelectAccountCallback = useCallback(
    async (selectedAccount: BDSAccountSwitcherAccount) => {
      if (selectedAccount.id !== accessTokenId) {
        await dispatch(setCurrentAccessToken(selectedAccount.id))
        const validatedToken = userDataLib.getAccountByAccessToken(selectedAccount.id)
        if (validatedToken) {
          dispatch(setCurrentBccAccount(validatedToken.bccId as string))
        }
        dispatch(
          forensicsGetEssAccount({
            accessTokenId: selectedAccount.id
          })
        )
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.NAVBAR_SWITCH_ACCOUNT, { selectedAccountId: selectedAccount.id })

        routesConfig.REMEDIATION.goto({
          accessToken: selectedAccount.id
        })
      }
    },
    [accessTokenId, dispatch, userDataLib]
  )

  const navbarContextMenuO365Props: NavbarContextMenuO365Props = useMemo(
    () => ({
      accounts
    }),
    [accounts]
  )

  return useMemo(
    () => [
      {
        navbarContextMenuO365Props,
        onSelectAccountCallback
      }
    ],
    [navbarContextMenuO365Props, onSelectAccountCallback]
  )
}
