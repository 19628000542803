import React, { useMemo } from 'react'

import Alert from 'global/components/lib/alerts/Alert'
import usePermissionBannerLogic from 'global/components/lib/layout/permissionBanner/usePermissionBannerLogic'

const PermissionBanner: React.FC = () => {
  const [alertConfig, , showPermissionBanner] = usePermissionBannerLogic()
  const { alertContent, closeAction, closeText, icon, pageAlert, showClose, variant } = alertConfig

  return useMemo(() => {
    if (!showPermissionBanner) {
      return null
    }

    return (
      <Alert
        icon={icon}
        pageAlert={pageAlert}
        variant={variant}
        showClose={showClose}
        alertContent={alertContent}
        closeAction={closeAction}
        closeText={closeText}
      />
    )
  }, [alertContent, closeAction, closeText, icon, pageAlert, showClose, showPermissionBanner, variant])
}

export default PermissionBanner
