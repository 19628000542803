import { makeStyles } from '@material-ui/core/styles'

export const navbarHeight = 58

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      '& .MuiToolbar-gutters': {
        padding: theme.spacing(0, 2)
      },
      '& .MuiToolbar-regular': {
        minHeight: navbarHeight
      }
    }
  },
  flexGrow: {
    flexGrow: 1
  },
  appBar: {
    backgroundColor: `${theme.colors.bgEmailProtection} !important`,
    height: navbarHeight,
    zIndex: '1200 !important' as any
  },
  toolBar: {
    minHeight: `${navbarHeight}px !important`
  },
  contentWrapper: {
    alignItems: 'center',
    display: 'flex',
    width: '100%'
  },
  navigationHamburger: {
    marginRight: theme.spacing(3)
  },
  navbarProductLogo: {
    display: 'flex',
    height: 40
  },
  navbarLogoText: {
    color: theme.palette.common.white
  },
  noticeableWrapper: {
    padding: '0px 23px',
    marginRight: theme.spacing(2),
    borderRight: `1px solid ${theme.palette.divider}`
  },
  controlWrapper: {
    alignItems: 'center',
    display: 'flex'
  },
  vertButton: {
    width: 24,
    height: 24
  },
  vertPopover: {
    position: 'fixed',
    zIndex: '1500 !important' as any
  },
  navbarEmail: {
    display: 'block',
    fontWeight: theme.font.weight.medium,
    marginBottom: theme.spacing(1)
  },
  navbarSerial: {
    '& svg': {
      marginLeft: theme.spacing(1),
      verticalAlign: 'bottom'
    }
  },
  navbarMenuContextItem: {
    width: 'auto !important'
  },
  // AccountSwitcher
  accountSwitcherButton: {
    color: `${theme.palette.info.contrastText} !important`,
    borderColor: `${theme.palette.info.contrastText} !important`,
    borderRadius: '2px',
    stroke: `${theme.palette.info.contrastText} !important`,
    backgroundColor: '#444444 !important',

    '&:hover': {
      backgroundColor: '#575757 !important',
      borderColor: `${theme.palette.info.contrastText} !important`
    }
  },
  accSwitcherPopover: {
    zIndex: '1500 !important' as any
  },
  accountMenuItem: {
    width: 'auto !important',
    justifyContent: 'space-between'
  },
  currentAccountMenuItem: {
    boxShadow: `5px 0 0 0 ${theme.palette.primary.dark} inset`
  },
  menuItemText: {
    justifyContent: 'flex-start',
    maxWidth: '320px',
    minWidth: '64px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginRight: theme.spacing(2)
  },
  // SideMenu
  list: {
    paddingTop: 70,
    width: 320
  },
  activeListItem: {
    backgroundColor: theme.palette.hover,
    boxShadow: `5px 0 0 0 ${theme.palette.primary.dark} inset`
  },
  listItemText: {
    cursor: 'pointer'
  },
  hamburger: {
    paddingTop: '8px'
  },
  drawer: {
    zIndex: '1100 !important' as any,
    flexShrink: 0
  },
  subMenu: {
    backgroundColor: '#F7F7F7'
  },
  bannerButton: {
    color: `${theme.palette.info.contrastText} !important`,
    display: 'flex',
    fontSize: theme.font.size.medium
  },
  warningBanner: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 'inherit',
    color: theme.palette.primary.contrastText,
    display: 'flex',
    padding: theme.spacing(1),
    width: 300
  }
}))
