import React from 'react'

import {
  IconButton,
  Alert as MaterialAlert,
  AlertProps as MaterialAlertProps,
  Typography
} from '@barracuda-internal/bds-core'
import { Close as CloseIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import styles from './AlertStyles'

export interface AlertProps extends MaterialAlertProps {
  alertContent: any
  closeAction?: any
  closeText?: string
  pageAlert?: boolean
  severity?: 'error' | 'success' | 'info' | 'warning'
  showClose?: boolean
  variant?: 'filled' | 'outlined' | 'standard'
}

const Alert: React.FC<AlertProps> = ({
  alertContent,
  className = '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeAction = () => {},
  closeText,
  pageAlert = false,
  severity = 'error',
  showClose = false,
  variant = 'filled',
  ...rest
}) => {
  const classes = styles()
  return (
    <MaterialAlert
      {...rest}
      variant={variant}
      severity={severity}
      className={`${pageAlert ? classes.pageAlert : ''}${className}`}
      action={
        showClose ? (
          <IconButton aria-label="close" size="small" color="inherit" onClick={closeAction}>
            {closeText ? <Typography variant="body1">{closeText}</Typography> : <CloseIcon />}
          </IconButton>
        ) : (
          undefined
        )
      }
    >
      {alertContent}
    </MaterialAlert>
  )
}

export default Alert
