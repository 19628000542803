import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  breadcrumb: {
    margin: theme.spacing(2)
  },
  emailsTotalCountWrapper: {
    display: 'inline-block',
    margin: theme.spacing(2, 2, 0, 2),
    minWidth: 400,
    padding: theme.spacing(2)
  },
  emailsTotalDates: {
    fontWeight: theme.font.weight.regular
  },
  emailsTotalCount: {
    fontSize: theme.font.size.xxlarge,
    fontWeight: theme.font.weight.medium
  },
  pageAlert: {
    margin: theme.spacing(2, 2, 0, 2)
  },
  title: {
    margin: theme.spacing(2, 2, 0, 2)
  },
  wrapper: {
    margin: theme.spacing(2, 2, 1, 2),
    overflow: 'hidden',
    position: 'relative'
  }
}))
