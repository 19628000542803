import React, { useMemo } from 'react'

import { Grid } from '@barracuda-internal/bds-core'
import SettingsTabs from 'fir/components/lib/settingsTabs/SettingsTabs'
import Tabs from 'global/components/lib/Tabs/Tabs'

import Layout from 'fir/components/lib/layout/Layout'

import useSettingsLogic, { UseSettingsParams } from './useSettingsLogic'

type SettingsProps = UseSettingsParams

const Settings: React.FC<SettingsProps> = props => {
  const [settingsLogic] = useSettingsLogic(props)
  const { pageTitle, settingsTabsConfig, tabsConfig } = settingsLogic

  return useMemo(() => {
    return (
      <Layout pageTitle={pageTitle}>
        <Grid container>
          <Grid item xs={3}>
            <Tabs {...tabsConfig} />
          </Grid>
          <Grid item xs={9}>
            <SettingsTabs {...settingsTabsConfig} />
          </Grid>
        </Grid>
      </Layout>
    )
  }, [pageTitle, settingsTabsConfig, tabsConfig])
}

export default Settings
